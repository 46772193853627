import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  position: relative;
`

const MenuWrapper = styled.div`
  position: relative;
`

const MenuButton = styled.button`
  position: absolute;
  box-shadow: 2px 2px 2px black;
  bottom: 60px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  right: 20px;
  border: none;
  background: white;
  webkit-transition: box-shadow .08s linear,min-width .15s cubic-bezier(0.4,0.0,0.2,1);
  transition: box-shadow .08s linear,min-width .15s cubic-bezier(0.4,0.0,0.2,1);
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.502), 0 1px 3px 1px rgba(60,64,67,0.249);
  cursor: pointer;

  &:active, &:focus, &:visited {
    outline: none;
  }

  @media(min-width: 769px) {
    display: none;
  }
`

const PageWrapper = styled.div`
  width: 100%;
  padding: 60px 40px;
  height: 100vh;
  background-color: #F7FAFF;
  overflow-y: auto;
  position: relative;
  @media(min-width: 769px) {
    width: calc(100% - 215px);
  }
`

export {
  Wrapper,
  MenuButton,
  PageWrapper,
  MenuWrapper
}