import PropTypes from "prop-types";
import { Img } from "./Components";

const iconSrc = (icon) => `/images/${icon}.svg`;

const Icon = ({ icon, width, height, style }) => {
  let src = "";

  switch (icon) {
    case "person-add":
    case "home":
    case "account":
    case "vaccine":
    case "logout":
    case "syringe":
    case "upload":
    case "doctor":
    case "edit":
    case "backspace":
    case "file":
    case "ufile":
    case "badge":
      src = iconSrc(icon);
      break;
    default:
    //silent
  }

  if (src) {
    return (
      <Img
        iconWidth={width || null}
        iconHeight={height || null}
        style={style || {}}
        src={src}
      />
    );
  } else {
    return null;
  }
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
};

export default Icon;
