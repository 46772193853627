import React, { useState, useContext, useEffect } from 'react';
import Input from '../Input/Input';
import { InputWrapper, Form, Title, Summary, SummaryDetails, IconButton, SubmitButton } from './Component';
import config from './config.js';
import Icon from '../Icon/Icon';
import Select from '../Select/Select';
import { getInputValuesFromForm, request } from '../../utils';
import { TranslationsContext } from '../../context/Translations';
import axios from 'axios';

const UserDetails = ({ details, summary, showSummary, refreshUser }) => {

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [gender, setGender] = useState(details.gender);
  const [nationality, setNationality] = useState(details.nationality);
  const [countries, setCountriesList] = useState([])

  const { translations } = useContext(TranslationsContext)
  const { inputs } = config(translations);

  useEffect(() => {
    const getCountriesList = async () => {
      try {
        const { data: result } = await axios.get('/find-countries-list');
        setCountriesList(result);
      } catch (error) {
        console.log('[RegistrationForm] getCountriesList Error:', error);
      }
    }
    getCountriesList();
  }, [])

  const getValue = (name) => {

    let value = '';
    let nameToMacth = '';

    if (name.includes('address')) {
      nameToMacth = 'address'
    } else {
      nameToMacth = name;
    }

    switch (nameToMacth) {
      case 'country':
        value = details['country']['country_relation']['country']['name'];
        break;
      case 'address':
        const key = name.split('.')[1];
        value = (details['address'] && details['address'][key]) || '';
        break;
      case 'gender':
        value = gender;
        break;
      case 'nationality':
        value = nationality;
        break;
      default:
        value = details[name];
    }

    return value;
  }

  const onUserUpdate = async (e) => {
    try {
      setLoading(true)
      const values = getInputValuesFromForm(e, ['text', 'email', 'select']);

      let valuesToSend = {
        'address': {},
        gender: gender,
        nationality: nationality
      };

      Object.keys(values).forEach(key => {
        if (key.includes('address')) {
          const secondField = key.split('.')[1];
          valuesToSend['address'][secondField] = values[key]
        } else {
          valuesToSend[key] = values[key]
        }
      })

      const { data } = await request.put(`/hcps/users/${details._id}`, valuesToSend);

      if (data.ok) {
        refreshUser && refreshUser()
      }

      setSubmitted(true)

    } catch (e) {
      //
    } finally {
      setLoading(false)
    }
  }

  const getOptions = (name) => {
    if (name === "gender") {
      return [
        { value: 'male', label: translations['USER_PROFILEPAGE_GENDER_MALE'] },
        { value: 'female', label: translations['USER_PROFILEPAGE_GENDER_FEMALE'] }
      ]
    } else if (name === "nationality") {
      return (countries || []).map(({ name }) => {
        return {
          value: name,
          label: name
        }
      })
    }
  }

  const onSelectChange = (name, value) => {
    if (name === 'gender') {
      setGender(value)
    } else if (name === 'nationality') {
      setNationality(value)
    }
  }

  return (
    <>
      <Title summary={summary.toString()}>
        {!summary && <IconButton onClick={() => { showSummary(prevState => !prevState); setSubmitted(false) }}>
          <Icon icon="backspace" height="16px" width="16px" />
        </IconButton>}
        <span>
          {translations["HCP_ADD_VACCINE_USER_DETAILS_TITLE"]}
        </span>
        {summary && <IconButton onClick={() => { showSummary(prevState => !prevState); setSubmitted(false) }}>
          <Icon icon="edit" height="16px" width="16px" />
        </IconButton>}
      </Title>
      {summary ?
        <Summary>
          <SummaryDetails email>
            <b>{translations["HCP_ADD_VACCINE_USER_DETAILS_EMAIL"]} </b>
            {details['email']}
          </SummaryDetails>
          <SummaryDetails>
            <b>{translations["HCP_ADD_VACCINE_USER_DETAILS_NAME"]} </b>
            {`${details['name'] || ''} ${details['surname'] || ''}`}
          </SummaryDetails>
        </Summary>
        :
        <Form id={'update-user-form'} onSubmit={onUserUpdate}>
          {
            (inputs || []).map((input, index) => {
              const { name, type } = input;
              return (
                <InputWrapper key={`${name}-${index}`}>
                  {
                    type === 'select' ? <Select
                      {...input}
                      labelId={`label-${type}-${name}-${index}`}
                      id={`${type}-${name}-${index}`}
                      options={getOptions(name)}
                      value={getValue(name)}
                      onChange={(value) => onSelectChange(name, value)}
                    /> : <Input defaultValue={getValue(name)} {...input} />
                  }
                </InputWrapper>
              )
            })
          }
          <SubmitButton form={'update-user-form'} loading={loading.toString()} disabled={submitted}>
            {submitted ? translations["HCP_ADD_VACCINE_USER_DETAILS_FORM_BUTTON_SUCCESS"] : translations["HCP_ADD_VACCINE_USER_DETAILS_FORM_BUTTON"]}
          </SubmitButton>
        </Form>}
    </>
  )

}

export default UserDetails;