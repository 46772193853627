import { v4 as uuidV4 } from "uuid";

export const getUserToken = () => {
  const jwt =
    sessionStorage.getItem("__session") || localStorage.getItem("__session");
  return (jwt || "").replace(/['"]+/g, "");
};

export const logout = (history, confirmationText) => {
  const response = confirm(confirmationText);
  if (response === true) {
    localStorage.removeItem("__session");
    sessionStorage.removeItem("__session");
    history.push("/");
  }

  return response;
};

export const validateEmail = (email) => {
  const emailRegExp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegExp.test(email);
};

export { requestClient as request } from "../../utils/request/";

export const getInputValuesFromForm = (
  event,
  inputTypes,
  options = { skipNames: [] }
) => {
  event.preventDefault();

  const inputArray = Array.from(event.currentTarget.elements);

  let obj = {};
  let allowedInputTypes = inputTypes ? inputTypes : [];

  inputArray.forEach((e) => {
    if (
      (e.tagName === "INPUT" ||
        e.tagName === "TEXTAREA" ||
        e.tagName === "SELECT") &&
      allowedInputTypes.indexOf(e.type) !== -1 &&
      options.skipNames.indexOf(e.name) === -1
    ) {
      obj[e.name] = e.value;
    }
  });

  return obj;
};

export const endsWith = (str = "", last = "") => {
  if (typeof str === "string") {
    const length = str.length;
    const lastChar = str[length - 1];
    return lastChar === last;
  }
};

export const startsWith = (str = "", first = "") => {
  if (typeof str === "string") {
    const firstChar = str[0];
    return firstChar === first;
  }
};

export const buildUrl = (paths = []) => {
  const completePath = paths.reduce((result, curr) => {
    let newResult = "";

    if (startsWith(result, "/")) {
      newResult = `${result}`;
    } else {
      newResult = `/${result}`;
    }
    if (endsWith(newResult, "/")) {
      newResult = `${newResult}`;
    } else {
      newResult = `${newResult}/`;
    }

    if (startsWith(curr, "/")) {
      if (endsWith(newResult, "/")) {
        newResult = `${newResult}${curr.substr(1)}`;
      } else {
        newResult = `${newResult}${curr}`;
      }
    } else {
      if (endsWith(newResult, "/")) {
        newResult = `${newResult}${curr}`;
      } else {
        newResult = `${newResult}/${curr}`;
      }
    }

    if (endsWith(newResult, "/")) {
      newResult = `${newResult}`;
    } else {
      newResult = `${newResult}/`;
    }

    return newResult;
  }, "");

  return completePath;
};

export const generateKeys = (numberOfKeys = 1) => {
  const keys = [];
  for (let i = 0; i < numberOfKeys; i++) {
    keys.push(uuidV4());
  }
  return keys;
};

export const configEnv = {
  // REACT_APP_HASH_API: "http://localhost:5000"
  REACT_APP_HASH_API: "https://hash.immunie.net",
};
