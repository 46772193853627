import React, { useContext } from 'react';
import { HcpContext } from '../context/Hcp';
import NotApproved from '../components/NotApproved/NotApproved';
import { Redirect } from 'react-router-dom';


const hcpApprovedOnly = (component) => {

  const BaseComponent = (props) => {

    const { state: hcpState } = useContext(HcpContext);

    if (!hcpState) {
      return <Redirect to={'/'} />
    }

    const status = hcpState.data.vetting_status;

    if (status === 'approved') {
      const Component = component;
      return <Component {...props} />
    }

    return <NotApproved status={status} {...props} />
  }


  return BaseComponent;

}

export default hcpApprovedOnly;