import styled from 'styled-components';
import { TextField } from '@material-ui/core';

const StyledInput = styled(TextField)`
  background: #FFFFFF;
  border: none;
  border-bottom: ${props => props.error ? 'none' : '1px solid #EDEDED'} !important;
  margin-bottom: ${props => props.error ? '16px' : '32px'} !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 13.3333px !important;
  width: 100%;

  &:focus, &:visited, &:active {
    outline: none;
  }

  & input, & input::placeholder, & label { 
    font-size: 13.333px !important;
    margin-bottom: 8px !important;
  }
`

export { StyledInput }