import React from 'react';
import PropTypes from 'prop-types';

const RenderWhen = ({ when, componentToRender, redirectComponent }) => {
  if (typeof componentToRender === 'function' && when === true) {
    const Component = componentToRender;
    return <Component />
  } else if (typeof redirectComponent === 'function' && when === false) {
    const Redirect = redirectComponent;
    return <Redirect />
  } else {
    return null;
  }
}

RenderWhen.propTypes = {
  when: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.instanceOf('undefined')
  ]),
  componentToRender: PropTypes.func,
  redirectComponent: PropTypes.func
}

export default RenderWhen