import React, { useState, useContext } from "react";
import LeftMenu from "../../components/LeftMenu/LeftMenu";
import RoutesBuilder from "../../components/RoutesBuilder/RoutesBuilder";
import Homepage from "../Homepage/Homepage";
import Vaccines from "../Vaccines/Vaccines";
import VaccinesTranscription from "../VaccinesTranscription/VaccinesTranscription";
import DigitalCertificates from "../DigitalCertificates/DigitalCertificates";
import NewUser from "../NewUser/NewUser";
import ProfilePage from "../ProfilePage/ProfilePage";
import FeedbackPage from "../FeedbackPage/FeedbackPage";
import { Wrapper, PageWrapper, MenuWrapper, MenuButton } from "./Components";
import { generateKeys } from "../../utils/";
import { Menu as MenuIcon } from "@material-ui/icons";
import { ClickAwayListener } from "@material-ui/core";
import SelectLangs from "../../components/SelectLangs/SelectLangs";

const keys = generateKeys(7);

const Dashboard = (props) => {
  const [showMenu, setShowMenu] = useState(false);

  const {
    match: { path },
    location: { pathname },
    history,
  } = props;

  const ROUTES = [
    {
      path: "/",
      exact: true,
      key: keys[0],
      component: Homepage,
    },
    {
      path: "/vaccines",
      exact: true,
      key: keys[1],
      component: Vaccines,
    },
    {
      path: "/users",
      exact: true,
      key: keys[2],
      component: NewUser,
    },
    {
      path: "/profile",
      exact: true,
      key: keys[3],
      component: ProfilePage,
    },
    {
      path: "/success-feedback",
      exact: true,
      key: keys[4],
      component: FeedbackPage,
    },
    {
      path: "/transcribe-records",
      exact: true,
      key: keys[5],
      component: VaccinesTranscription,
    },
    {
      path: "/digital-certificates",
      exact: true,
      key: keys[6],
      component: DigitalCertificates,
    },
  ];

  const onClickAway = (e) => {
    try {
      let handleMenu = true;

      const id = e.target.id;
      const nodeName = e.target.nodeName;

      if (id.includes("menu-button")) handleMenu = false;
      if (nodeName === "path") {
        const parentId = e.path[1].id;
        if (parentId.includes("menu-button")) handleMenu = false;
      }

      if (showMenu === true && handleMenu === true) {
        setShowMenu(false);
      }
    } catch (e) {
      //silent
    }
  };

  return (
    <Wrapper>
      <ClickAwayListener onClickAway={onClickAway}>
        <MenuWrapper>
          <LeftMenu
            path={path}
            pathname={pathname}
            show={showMenu}
            showMenu={setShowMenu}
            history={history}
          />
        </MenuWrapper>
      </ClickAwayListener>
      <PageWrapper>
        <SelectLangs />
        <RoutesBuilder routesProps={ROUTES} rootPath={path} />
      </PageWrapper>
      <MenuButton
        id={"menu-button"}
        onClick={() => setShowMenu((prevState) => !prevState)}
      >
        <MenuIcon id={"menu-button-icon"} />
      </MenuButton>
    </Wrapper>
  );
};

export default Dashboard;
