import styled from 'styled-components';

const Container = styled.div`
  max-width: 220px;
  width: 100%;
`

const Uploader = styled.div`
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #A4DBFF;
  color: #000000;
  box-shadow: 0px 3px 6px rgba(0,0,0, 0.16);
  padding: 20px;
  width: 100%;
  font-size: 12px;
  cursor: pointer;
`

const Label = styled.p`
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  margin-bottom: 8px;
  padding-left: 8px;
`

const Filename = styled.pre`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-top: 4px;
  font-weight: bold;
  text-align: center;
`

export { Container, Uploader, Label, Filename }