import React, { useContext } from 'react';
import Feedback from '../Feedback/Feedback';
import { Background, BlurredEffect } from './Components';
import { TranslationsContext } from '../../context/Translations';

const NotApproved = ({ status, location }) => {

  const { translations } = useContext(TranslationsContext)

  const message = {
    pending: `${translations["HCP_NOT_APPROVED_VETTING_PROGRESS"]} <br /> ${translations["HCP_NOT_APPROVED_VETTING_NOTIFIED"]}`,
    denied: `${translations["HCP_NOT_APPROVED_VETTING_DENIED"]} <br /> ${translations["HCP_NOT_APPROVED_VETTING_CONTACT"]}`
  }

  const { pathname } = location;

  const url = (src) => `url('${src}')`

  const getStyles = (pathname) => {
    if (pathname.includes('users')) {
      return {
        backgroundImage: url('/images/users_mock.png'),
        backgroundSize: '80%'
      };
    } else if (pathname.includes('profile')) {
      return {
        backgroundImage: url('/images/profile_mock.png'),
        backgroundSize: '70%'
      };
    } else if (pathname.includes('vaccines')) {
      return {
        backgroundImage: url('/images/vaccines_mock.png'),
        backgroundSize: '100%'
      };
    } else {
      return {
        backgroundImage: url('/images/dashboard_mock.png'),
        backgroundSize: '100%'
      };
    }
  }

  return (
    <Background>
      <BlurredEffect style={getStyles(pathname)} />
      <Feedback message={message[status]} />
    </Background>
  )
}

export default NotApproved;