import React, { useContext } from "react";
import { TranslationsContext } from "../../context/Translations";
import {
  Body,
  Message,
  Row,
  Vaccines,
  IconWrapper,
  Details,
  VaccineName,
  UserName,
  Date,
} from "./Components";
import Icon from "../../components/Icon/Icon";
import { generateKeys } from "../../utils";

const VaccineHistory = ({ vaccines = [] }) => {
  const { translations } = useContext(TranslationsContext);

  return (
    <Body>
      {vaccines.length === 0 ? (
        <Message>
          {translations["HCP_HOMEPAGE_LAST_VACCINES_NO_VACCINES"]}
        </Message>
      ) : (
        <Vaccines>
          {vaccines.map((item) => {
            return (
              <Row id={`${item.name}+${item.lotNumber}`} key={generateKeys(6)}>
                <IconWrapper>
                  <Icon icon={"syringe"} height={"18px"} width={"18px"} />
                </IconWrapper>
                <Details>
                  <VaccineName>
                    {item.vaccine || item.antibody} {`(${item.pharma})`}
                  </VaccineName>
                  <UserName>
                    {item.name} {item.surname} {`(${item.lotNumber})`}
                  </UserName>
                  <Date type={"desktop"}>{item.createdAt}</Date>
                </Details>
                <Date type={"mobile"}>{item.createdAt}</Date>
              </Row>
            );
          })}
        </Vaccines>
      )}
    </Body>
  );
};

export default VaccineHistory;
