import React, { useState, useContext, useEffect } from "react";
import hcpApprovedOnly from "../../HOC/HcpApprovedOnly";
import moment from "moment";
import { TranslationsContext } from "../../context/Translations";
import { HcpContext } from "../../context/Hcp";
import {
  Page,
  Header,
  InputWrapper,
  Button,
  PageBody,
  Form,
  Error,
} from "./Components";
import Input from "../../components/Input/Input";
import Select from "../../components/Select/Select";
import Grid from "@material-ui/core/Grid";
import VaccineCardTranscription from "../../components/VaccinationCardTranscription/VaccineCardTranscription";
import { request, getInputValuesFromForm } from "../../utils";

const VaccineTranscription = () => {
  const [code, setCode] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [countries, setCountries] = useState([]);
  const [countryPlans, setCountryPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [planError, setPlanError] = useState("");
  const [user, setUser] = useState(null);

  const { translations } = useContext(TranslationsContext);
  const { state: hcpstate } = useContext(HcpContext);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const { data } = await request.get("/hcps/form-data");
        const countriesOptions = (data || []).map((country) => {
          const {
            _id,
            populated_country: {
              country: { name },
            },
          } = country;
          return {
            value: _id,
            label: name,
            plans: country.plans.map((plan) => {
              return {
                value: plan._id,
                label: `${plan.displayName} (${plan.currency} ${plan.price})`,
                price: plan.price,
              };
            }),
          };
        });

        setCountries(countriesOptions);
      } catch (e) {
        //
      }
    };
    getCountries();
  }, []);

  const fetchUser = async () => {
    try {
      setIsLoading(true);
      setError(false);
      const { data } = await request.get(`/hcps/user?code=${code}`);
      setUser(data);
    } catch (e) {
      setError(true);
      setUser(null);
    } finally {
      setIsLoading(false);
    }
  };

  const updateUserPlan = async (e) => {
    try {
      setIsLoading(true);

      const values = getInputValuesFromForm(e, ["text", "email", "select"]);

      if (values.plan === "placeholder") {
        throw { message: translations["PLAN_ERROR_SELECT_VALID"] };
      }

      const updateForm = { plan: values.plan };

      if (hcpstate.data.clinic) {
        updateForm["planCreatedAt"] = hcpstate.data.clinic._id;
        updateForm["planCreatedBy"] = hcpstate.data._id;
      }

      const selectedCountry = countries.find(
        (country) => country.value === values.country
      );

      const selectedPlan = selectedCountry.plans.find(
        (plan) => plan.value === updateForm["plan"]
      );

      updateForm["planPrice"] = selectedPlan.price;
      updateForm["planStartDate"] = moment().format("YYYY-MM-DD");

      const { data } = await request.put(
        `/hcps/users/${user.details._id}`,
        updateForm
      );

      if (data.ok) {
        setPlanError("");
        const { data } = await request.get(`/hcps/user?code=${code}`);
        setUser(data);
        setIsLoading(false);
      }
    } catch (e) {
      let errorMessage = "";

      const { isAxiosError } = e;

      if (isAxiosError) {
        errorMessage =
          typeof e.response.data.message === "string"
            ? e.response.data.message
            : e.message;
      } else {
        errorMessage = e.message;
      }

      setPlanError(errorMessage);
      setIsLoading(false);
    }
  };

  const onCodeChange = ({ target: { value } }) => {
    if (value.length < 6) {
      setCode(value);
      setButtonDisabled(true);
    } else if (value.length === 6) {
      setCode(value);
      setButtonDisabled(false);
    }
  };

  return (
    <Page>
      <Header>
        <InputWrapper>
          <Input
            label={translations["HCP_ADD_VACCINE_LABEL_ACCESS_CODE"]}
            placeholder={
              translations["HCP_ADD_VACCINE_LABEL_ACCESS_CODE_PLACEHOLDER"]
            }
            name={"accessCode"}
            value={code}
            onChange={onCodeChange}
            required
          />
        </InputWrapper>
        <Button
          onClick={fetchUser}
          disabled={buttonDisabled}
          loading={isLoading.toString()}
        >
          {translations["HCP_ADD_VACCINE_FIND_USER"]}
        </Button>
      </Header>
      <PageBody appear={error || !!user}>
        {error ? (
          <span>{translations["HCP_ADD_VACCINE_NOT_FIND_USER"]}</span>
        ) : user ? (
          user.details.plan ? (
            <VaccineCardTranscription user={user} refreshUser={fetchUser} />
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <p>{translations["PLAN_MISSING_TEXT"]}</p>
              </Grid>
              <Grid item xs={12}>
                <Form id="update-user-form" onSubmit={updateUserPlan}>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Select
                        style={{
                          width: "100%",
                          marginTop: "auto",
                          height: "53.83px",
                        }}
                        label={"Country"}
                        name={"country"}
                        labelId={"update-user-country-label"}
                        fallbackMessage={
                          translations["HCP_CREATE_USER_NO_OPTIONS"]
                        }
                        errorMessage={null}
                        options={countries}
                        defaultValue={""}
                        onChange={(countryId) => {
                          const selectedCountry = countries.find(
                            (country) => country.value === countryId
                          );
                          setCountryPlans(selectedCountry.plans);
                        }}
                        required
                        classes={{
                          root: "select-input-update-user",
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Select
                        style={{
                          width: "100%",
                          marginTop: "auto",
                          height: "53.83px",
                        }}
                        label={"Plan"}
                        name={"plan"}
                        labelId={"update-user-plan-label"}
                        errorMessage={null}
                        options={countryPlans}
                        fallbackMessage={
                          translations["HCP_CREATE_USER_NO_OPTIONS"]
                        }
                        defaultValue={""}
                        required
                        classes={{
                          root: "select-input-update-user",
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <Button form={"update-user-form"} loading={isLoading.toString()}>
                      {translations["USER_UPDATE_PLAN_BTN_TEXT"]}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Error style={{ margin: "0 auto" }}>{planError}</Error>
                  </Grid>
                </Form>
              </Grid>
            </Grid>
          )
        ) : null}
      </PageBody>
    </Page>
  );
};

export default hcpApprovedOnly(VaccineTranscription);
