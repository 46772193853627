import React, { useState, useContext } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField
} from "@material-ui/core/";
import { ErrorMessage, StyledButton } from "./Components";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { request, configEnv } from "../../utils/";
import { requestBlockchain } from "../../../utils/request";
import { HcpContext } from "../../context/Hcp";
import { TranslationsContext } from "../../context/Translations";

import "./CardItem.css";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "0.75rem",
    fontWeight: "400",
    marginRight: 12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  select: {
    width: "200px",
    marginRight: "15px",
    fontSize: "0.75rem",
    height: "100%",
  },
  textField: {
    width: "200px",
    marginRight: "15px",
    fontSize: "0.75rem",
  },
  button: {
    height: "85%",
  },
}));

const CardItem = ({
  vaccineName,
  vaccineId,
  applied,
  producers,
  userId,
  vaccineType,
  hcpName,
  hcpNumber,
  vaccineLotNumber,
  date,
  producerName,
  wasTranscribed,
  transcription,
  vaccBrandName,
  refreshUser,
  isIcv
}) => {
  const classes = useStyles();
  const [producer, setProducer] = useState("");
  const [lotNumber, setLotNumber] = useState("");
  const [brandName, setBrandName] = useState("");
  const [error, setError] = useState({ producer: false, lotNumber: false });
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState("");
  const [alertWasDisplayed, setAlertWasDisplayed] = useState(false)

  const {
    state: { data: hcp },
  } = useContext(HcpContext);
  const { translations, selectedLang } = useContext(TranslationsContext);

  const validateLotNumber = async (
    producer,
    lotNumber,
    vaccineId,
    vaccineType
  ) => {
    return await request.get(
      `/hcps/validate-lotnumber?producer=${producer}&lotNumber=${lotNumber}&vaccineId=${vaccineId}&vaccineType=${vaccineType}`
    );
  };


  const applyVaccine = async (
    vaccineId,
    userId,
    hcp,
    lotNumber,
    vaccineType,
    producer,
    brandName
  ) => {
    if (isIcv && !alertWasDisplayed) {
      alert(translations["HCP_AREA_VACCINE_ICV_ALERT"])
      setAlertWasDisplayed(true);
      return;
    }

    return await request.post(
      `/hcps/register-vaccine?vaccine=${vaccineId}&user=${userId}&hcp=${hcp._id}&lotNumber=${lotNumber}&vaccineType=${vaccineType}&producer=${producer}&brandName=${brandName}&lang=${selectedLang}`
    );
  };

  const registerBlockchain = async (userId) => {
    const { data: result } = await requestBlockchain.get(
      `/client-app/certificates?id=${userId}`,
      {
        id: userId,
      }
    );

    // Skip blochain register if the user has no iCV vaccine.
    if (!result.vaccines.length) return;

    result.user = {
      ...result.user,
      id: userId,
    };

    const userData = result.user;
    const userVaccines = result.vaccinationRegistryIds;
    const lastAppliedDate = result.lastAppliedDate;

    const userDocumentType = userData.documentType;
    const userDocumentNumber = userData.documentNumber;
    const userFullName = userData.fullName;
    const userBirthday = userData.birthday;
    const userGender =
      userData.gender &&
      (userData.gender === "male"
        ? translations["USER_PROFILEPAGE_GENDER_MALE"]
        : translations["USER_PROFILEPAGE_GENDER_FEMALE"]);
    const userNationality = userData.nationality;

    const isThereEnoughInfo = !!(
      userFullName &&
      userDocumentType &&
      userDocumentNumber &&
      userGender &&
      userBirthday &&
      userNationality
    );

    if (isThereEnoughInfo) {
      return await requestBlockchain.post(
        `${configEnv.REACT_APP_HASH_API}/hash-register/vaccine`,
        {
          data: {
            userData,
            userVaccines,
            lastAppliedDate
          },
        }
      );
    } else {
      return;
    }
  };

  const onApply = async () => {
    try {
      setError({});
      setErrorMessage("");
      setLoading(true);

      if (!lotNumber) {
        setError({ lotNumber: true });
      }

      if (!producer) {
        setError({ producer: true });
      }

      if (!producer || !lotNumber) return;

      const { data: isValid } = await validateLotNumber(
        producer,
        lotNumber,
        vaccineId,
        vaccineType
      );

      if (isValid.ok) {
        await applyVaccine(
          vaccineId,
          userId,
          hcp,
          lotNumber,
          vaccineType,
          producer,
          brandName
        );
        await registerBlockchain(userId);
        await refreshUser();
      }
    } catch (e) {
      const isAxiosError = e.isAxiosError;
      if (isAxiosError) {
        if (
          // Hash already exist at blockchain
          e.response.data.status == 409
        ) {
          await refreshUser();
        } else {
          setErrorMessage(e.response.data.message);
        }
      } else {
        setErrorMessage(
          translations["HCP_ADD_VACCINE_CARD_SOMETHING_WENT_WRONG"]
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Accordion id={"card-item-accordion"}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading} align="center">
          {vaccineName + " "}
        </Typography>
        {!applied && (
            <Chip
              size="small"
              label={translations["HCP_ADD_VACCINE_CARD_NOT_APPLIED"]}
              className={classes.heading}
            />
          )}
          {wasTranscribed && (
            <Chip
              size="small"
              color="primary"
              label={translations["HCP_ADD_VACCINE_CARD_TRASNCRIBED"]}
              className={classes.heading}
            />
          )}
      </AccordionSummary>
      <AccordionDetails>
        {!applied ? (
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="producer-select" className={classes.heading}>
              {translations["HCP_ADD_VACCINE_CARD_PRODUCER"]}
            </InputLabel>
            <Select
              labelId="producer-select-label"
              value={producer || ""}
              onChange={({ target: { value } }) => {
                if (value === "not-found") return;
                setError({ producer: false });
                setProducer(value);
              }}
              label={translations["HCP_ADD_VACCINE_CARD_PRODUCER"]}
              className={classes.select}
              error={error["producer"]}
            >
              {producers.length > 0 ? (
                producers.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.commercialName}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={"not-found"} key={"not-found"}>
                  {translations["HCP_ADD_VACCINE_NO_OPTIONS"]}
                </MenuItem>
              )}
            </Select>
            <TextField
              error={error["brandName"]}
              className={classes.heading}
              value={brandName}
              label={translations["HCP_TEXT_BRAND_NAME_LABEL"]}
              variant="outlined"
              className={classes.textField}
              onChange={({ target: { value } }) => {
                setError({ brandName: false });
                setBrandName(value);
              }}
            />
            <TextField
              error={error["lotNumber"]}
              className={classes.heading}
              value={lotNumber}
              label={translations["HCP_ADD_VACCINE_CARD_LOT_NUMBER"]}
              variant="outlined"
              className={classes.textField}
              onChange={({ target: { value } }) => {
                setError({ lotNumber: false });
                setLotNumber(value);
              }}
            />
            <StyledButton
              color="primary"
              variant="contained"
              className={classes.button}
              size="small"
              onClick={onApply}
              loading={isLoading.toString()}
            >
              {translations["HCP_ADD_VACCINE_CARD_APPLY"]}
            </StyledButton>
          </FormControl>
        ) : (
          <div>
            {wasTranscribed ? (
              <>
                <Typography className={classes.heading}>
                  {translations["CLIENT_VACCINE_TRANSCRIPTION_DATE"]}:{" "}
                  <b>{date}</b>.
                </Typography>
                <Typography className={classes.heading}>
                  {translations["HCP_ADD_VACCINE_CARD_DATE"]}:{" "}
                  <b>
                    {transcription.date ? transcription.date : "Not Avaliable"}
                  </b>
                  .
                </Typography>
                <Typography className={classes.heading}>
                  {translations["HCP_TEXT_TRANSCRIBED_BY"]}: <b>{hcpName}</b>
                </Typography>
              </>
            ) : (
              <Typography className={classes.heading}>
                {translations["HCP_ADD_VACCINE_CARD_DATE"]}: <b>{date}</b>.
              </Typography>
            )}
            <Typography className={classes.heading}>
              {translations["HCP_ADD_VACCINE_APPLIED_BY"]}{" "}
              <b>
                {wasTranscribed
                  ? transcription.hcpName
                    ? transcription.hcpName
                    : translations["HCP_TEXT_NOT_AVAILABLE_INFO"]
                  : hcpName}
              </b>{" "}
              {translations["HCP_ADD_VACCINE_HCP_REGISTRY"]}{" "}
              <b>
                {wasTranscribed
                  ? transcription.registerNumber
                    ? transcription.registerNumber
                    : translations["HCP_TEXT_NOT_AVAILABLE_INFO"]
                  : hcpNumber}
              </b>
              .
            </Typography>
            <Typography className={classes.heading}>
              {translations["HCP_ADD_VACCINE_CARD_LOT_NUMBER"]}:{" "}
              <b>
                {wasTranscribed
                  ? transcription.lotNumber
                    ? transcription.lotNumber
                    : translations["HCP_TEXT_NOT_AVAILABLE_INFO"]
                  : vaccineLotNumber}
              </b>
              , {translations["HCP_ADD_VACCINE_PRODUCED_BY"]}{" "}
              <b>
                {wasTranscribed
                  ? transcription.producer
                    ? transcription.producer
                    : translations["HCP_TEXT_NOT_AVAILABLE_INFO"]
                  : producerName}
              </b>
              .
            </Typography>
            <Typography className={classes.heading}>
              {translations["HCP_TEXT_BRAND_NAME_LABEL"]}:{" "}
              <b>
                {vaccBrandName
                  ? vaccBrandName
                  : translations["HCP_TEXT_NOT_AVAILABLE_INFO"]}
              </b>
            </Typography>
            {wasTranscribed ? (
              <>
                <Typography className={classes.heading}>
                  {translations["HCP_TEXT_CLINIC_LABEL"]}:{" "}
                  <b>
                    {transcription.clinic
                      ? transcription.clinic
                      : translations["HCP_TEXT_NOT_AVAILABLE_INFO"]}
                  </b>{" "}
                </Typography>
                <Typography className={classes.heading}>
                  {translations["HCP_TEXT_VACC_NOTES_LABEL"]}:{" "}
                  <b>
                    {transcription.notes
                      ? transcription.brandName
                      : translations["HCP_TEXT_NOT_AVAILABLE_INFO"]}
                  </b>{" "}
                </Typography>
              </>
            ) : (
              ""
            )}
          </div>
        )}
      </AccordionDetails>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Accordion>
  );
};

export default CardItem;
