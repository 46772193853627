import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  ButtonBase,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  icon: {
    width: 80,
    height: 80,
    fontSize: 60,
  },
  documentCardText: {
    textAlign: "left",
    fontSize: ".8rem",
  },
}));

const FileList = ({ files, translations }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4} style={{ padding: "12px" }}>
        {files.map((uploadedFile) => (
          <Grid
            item
            md={12}
            lg={6}
            key={`${uploadedFile.id}_${new Date().getTime()}`}
          >
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item>
                  <ButtonBase className={classes.icon}>
                    <DescriptionOutlinedIcon fontSize="inherit" />
                  </ButtonBase>
                </Grid>
                <Grid item xs={12} container sm>
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs className={classes.documentCardText}>
                      <Typography
                        gutterBottom
                        style={{ fontWeight: 600, fontSize: ".9rem" }}
                      >
                        {uploadedFile.name.split(".pdf")[0]}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {`${translations["HCP_AREA_FILE_SIZE"]}: ${uploadedFile.readableSize}`}
                      </Typography>
                      {uploadedFile.error ? (
                        <Typography variant="body2" color="error">
                          {`${translations["HCP_AREA_FILE_STATUS"]} : ${uploadedFile.errorMessage}`}
                        </Typography>
                      ) : !uploadedFile.uploaded ? (
                        <Typography variant="body2" color="textSecondary">
                          {translations["HCP_AREA_FILE_STATUS"]}:{" "}
                          {translations["HCP_AREA_FILE_STATUS_UPLOADING"]}
                        </Typography>
                      ) : (
                        <Typography
                          variant="body2"
                          style={{ color: "#4caf50" }}
                        >
                          {translations["HCP_AREA_FILE_STATUS"]}:{" "}
                          {translations["HCP_AREA_FILE_STATUS_UPLOADED"]}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item style={{ alignSelf: "center" }}>
                    {!uploadedFile.uploaded && !uploadedFile.error ? (
                      <CircularProgress />
                    ) : uploadedFile.error ? (
                      <ErrorOutlineIcon
                        style={{ fontSize: 40, color: "#f44336" }}
                      />
                    ) : (
                      <CheckCircleOutlineIcon
                        style={{ fontSize: 40, color: "#4caf50" }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>

            {/* <div>
              <strong>{uploadedFile.name.split(".pdf")[0]}</strong>
              <span>
                {uploadedFile.readableSize}{" "}
                {!uploadedFile.uploaded
                  ? ` - (${translations["HCP_AREA_FILE_STATUS"]} : ${translations["HCP_AREA_FILE_STATUS_UPLOADING"]})`
                  : ` - (${translations["HCP_AREA_FILE_STATUS"]} : ${translations["HCP_AREA_FILE_STATUS_UPLOADED"]})`}
              </span>
              <span style={{ color: "red" }}>
                {uploadedFile.error ? uploadedFile.errorMessage : ""}
              </span>
            </div>

            <div>
              {!uploadedFile.uploaded && !uploadedFile.error && (
                <CircularProgressbar
                  styles={{
                    root: { width: 60 },
                    path: { stroke: "#7159c1" },
                  }}
                  strokeWidth={10}
                  value={uploadedFile.progress - 1}
                  text={`${uploadedFile.progress - 1}%`}
                />
              )}

              {uploadedFile.url && (
                <a
                  href={uploadedFile.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MdLink style={{ marginRight: 8 }} size={24} color="#222" />
                </a>
              )}

              {uploadedFile.uploaded && (
                <MdCheckCircle size={24} color="#78e5d5" />
              )}
              {uploadedFile.error && <MdError size={24} color="#e57878" />}
            </div> */}
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default FileList;
