import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Menu = styled.div`
  background-color: #3298DC;
  height: 100vh;
  max-height: 100vh;
  overflow: auto;
  max-width: 215px;
  transition: left 0.5s, width 0.5s;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: ${props => props.show ? '215px' : '0px'};
  min-width: ${props => props.show ? '215px' : '0px'};
  left: ${props => props.show ? '0px' : '-215px'};
  padding-bottom: 60px;
  z-index: 3;
  @media(min-width: 769px){
    position: relative;
    left: 0 !important;
    width: 215px !important;
  }
`

const Header = styled.h1`
  width: 100%;
  text-align: center; 
  color: #FFFFFF;
  margin: 0;
  padding: 12px 24px;
  margin-bottom: 8px;
  box-shadow: 0px 3px 6px #235089;
`

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  padding: 18px;
  text-decoration: none;
  cursor: pointer;
  background-color: ${props => (props.selected ? 'rgba(256,256,256, 0.1)' : 'transparent')};
  &:hover {
    background-color: rgba(256, 256, 256, 0.1);
  }
`

const Label = styled.p`
  margin: 8px 0 0 0;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
`

const Button = styled.button`
  width: calc(100% - 60px);
  border: 0px;
  color: rgba(24,32,46);
  height: 10vh;
  position: sticky;
  max-height: 45px;
  height: 45px;
  min-height: 45px;
  background-color: #FFFF;
  font-weight: bold;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  left: 50%;
  transform: translate(-25%,0);
  border-radius: 4px;
  bottom: -5px;
  margin: 12px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  &:active, &:visited, &:focus {
    outline: none;
  }
`

const ClinicLogo = styled.div`
  filter: brightness(0) invert(1);
  width: 100%;
  height: 60px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('${props => props.src}');
`

const Logo = styled.img`
  filter: brightness(0) invert(1);
  width: 40px;
  height: 40px;
  margin-left: 8px;
`

const Footer = styled.div`
  height: 3rem;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 3rem;
  position: fixed;
  bottom: -2px;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  color: white;
  width: 215px;
  align-items: center;
  background-color: #3298DC;
  box-shadow: -2px -3px 6px #235089;
`

export { Menu, Header, StyledLink, Label, Button, Logo, ClinicLogo, Footer }