import React from 'react';
import { Wrapper, Text } from './Components';

const Feedback = ({ message, button }) => {

  const Button = button;

  return (
    <Wrapper>
      <Text dangerouslySetInnerHTML={{ __html: message }} />
      {typeof button === 'function' ? <Button /> : null}
    </Wrapper>
  )
}

export default Feedback;