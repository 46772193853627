import React, { useRef, useState, useEffect, useContext } from 'react';
import { Container, Label, Uploader, Filename } from './Components';
import Icon from '../Icon/Icon';
import { generateKeys } from '../../utils/';
import { TranslationsContext } from '../../context/Translations';

const FileUploader = ({ label, onChange = () => { } }) => {

  const input = useRef(null);

  const [id, setId] = useState(null);
  const [filename, setFilename] = useState(null);
  const { translations } = useContext(TranslationsContext);

  useEffect(() => {
    const keys = generateKeys(1);
    setId(keys[0])
  }, [])

  const onClick = () => {
    try {
      const { current } = input;
      current.click();
    } catch (_) {
      //
    }
  }

  const handleChange = () => {
    try {
      if (onChange) {
        const target = document.getElementById(id);
        const file = target.files[0];
        const { name } = file;
        setFilename(name);
        onChange(file);
      }
    } catch (_) {
      //
    }
  }

  return (
    <Container>
      <Label>{label}</Label>
      <Uploader onClick={onClick}>
        <Icon icon="upload" />
        <span>{translations['HCP_FILE_UPLOADER']}</span>
        <input type={'file'} style={{ display: 'none' }} ref={input} onChange={handleChange} id={id} accept={"image/*"} />
        <Filename>{filename}</Filename>
      </Uploader>
    </Container>
  )
}

export default FileUploader;