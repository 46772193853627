import styled from 'styled-components';
import { Link } from 'react-router-dom';


const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1320px;
  margin: 0 auto;
  @media(min-width: 1024px) {
    flex-direction: row;
  }
`

const Container = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  background: rgb(255, 255, 255);
  box-shadow: rgb(227, 233, 243) 0px 2px 4px 0px;
  padding: 30px 19px;
  border-radius: 3px;
  width: 100%;
  margin: 12px 0;

  @media(min-width: 1024px) {
    max-width: ${props => props.type === 'details' ? 'calc(66.666667% - 12px)' : 'calc(33.333333333% - 12px)'};
    margin: ${props => props.type === 'details' ? '0 auto 0 0' : '0 0 0 auto'};
  }

`

const Title = styled.h1`
  font-size: 16px;
  margin-bottom: 0.5rem;
  font-weight: 500;
  margin-top: 0;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  &:after {
    content: "";
    width: 100%;
    height: 3px;
    margin-top: 4px;
    display: block;
    background: #3298DC;
    line-height: 24px;
  }
`

const Details = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 34px;
  line-height: 18px;
  
`

const IconLink = styled(Link)`
  text-transform: none;
  position: absolute;
  bottom: -8px;
  right: 0px;
`

const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  padding-top: 16px;
  justify-content: space-evenly;
  flex-wrap: wrap;
`

const CardLink = styled(Link)`
  padding: 12px;
  border-radius: 10px;
  box-shadow: 5px 3px 10px rgba(0,0,0,0.16);
  background-color: ${props => props.color};
  color: #FFFFFF;
  font-weight: 600px;
  line-height: 22px;
  margin-right: 6px;
  text-decoration: none;
  width: 35%;
  text-align: center;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 180px;
  margin-bottom: 12px;
`

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export { Wrapper, Container, Details, Title, IconLink, CardLink, CardWrapper, LoaderWrapper };