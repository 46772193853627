import styled from 'styled-components';

const Page = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1200px;
`

const Header = styled.div`
  width: 100%;
  padding: 12px;
  display: flex;
  align-items: center;
`

const InputWrapper = styled.div`
  max-width: 350px;
  width: 100%;
`

const Button = styled.button`
  padding: 12px;
  background-color: #3298DC;
  border: none;
  color: #FFF;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  margin-left: 24px;
  border-radius: 5px;
  margin-bottom: 32px !important;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  opacity: ${props => props.disabled ? '0.5' : '1'};

  background-image: radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.3) 10%, transparent 10%);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 0 0;

  animation: ripple 1s;
  animation-iteration-count: ${props => props.loading == 'true' ? 'infinite' : 0};

  @keyframes ripple {
    from {
      background-size: 0 0;
    }
    to {
      background-size: 1000% 1000%;
    }
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px;
  margin: 0 auto 20px auto;

  @media(min-width: 769px) {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
`

const PageBody = styled.div`
  width: calc(100% - 12px);
  margin-left: 12px;
  display:${props => props.appear ? 'flex' : 'none'};
  border: none;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: rgba(24,32,46);
  min-height: 300px;
  font-size: 16px;
  font-weight: bold;
  color: rgba(24,32,46);
  box-shadow: rgb(227, 233, 243) 0px 2px 4px;
  background: rgb(255, 255, 255);
  padding: 22px 28px;
  border-radius: 2px;
`

const Error = styled.pre`
  font-size: 14px;
  text-align: center;
  color: red;
`

export { Page, Header, InputWrapper, Button, PageBody, Form, Error }