import React from 'react';
import LoginForm from '../../components/LoginForm/LoginForm';
import AuthPageWrapper from '../../components/AuthPageWrapper/AuthPageWrapper';


const LoginPage = () => {
  try {
    return (
      <AuthPageWrapper
        bgImgSrc={'/images/doctors.svg'}
        form={() => <LoginForm history={history} />}
      />
    );
  } catch (e) {
    return null;
  }
};


export default LoginPage;
