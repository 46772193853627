import React from 'react';
import RegisterForm from '../../components/RegisterForm/RegisterForm';
import AuthPageWrapper from '../../components/AuthPageWrapper/AuthPageWrapper';

const RegisterPage = () => {
  try {
    return (
      <AuthPageWrapper
        form={() => <RegisterForm history={history} />}
        bgImgSrc={'/images/register.svg'}
      />
    );
  } catch (e) {
    return null;
  }
};


export default RegisterPage;
