import React, { useState, useEffect } from "react";
import axios from "axios";

export const TranslationsContext = React.createContext();

export const TranslationsProvider = ({ children }) => {
  const storedLanguage = localStorage.getItem("language");

  const [langs, setLangs] = useState([]);
  const [selectedLang, setSelectedLang] = useState(storedLanguage || "EN");
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const getLangs = async () => {
      try {
        const { data: langsArr } = await axios.get("/langs");
        setLangs(langsArr);
      } catch (e) {
        //
      }
    };
    getLangs();
  }, []);

  useEffect(() => {
    const getTranslations = async (selectedLang) => {
      try {
        const { data: translations } = await axios.get(
          `/languages/${selectedLang}.json`
        );
        setTranslations(translations);
      } catch (e) {
        //
      }
    };
    getTranslations(selectedLang);
  }, [selectedLang]);

  const reducer = (action, data) => {
    switch (action) {
      case "new-language":
        localStorage.setItem("language", data);
        setSelectedLang(data);
      default:
      //
    }
  };

  return (
    <TranslationsContext.Provider
      value={{
        langs,
        selectedLang,
        translations,
        dispatch: reducer,
      }}
    >
      {children}
    </TranslationsContext.Provider>
  );
};
