import axios from "axios";
const _ = require("lodash");
const jwt = require("jsonwebtoken");
const { jwtSecret } = require("./jwt");

const issueJwtToken = (payload) => {
  return jwt.sign({ id: payload.id }, jwtSecret, {
    expiresIn: 60 * 24 * 60,
  });
};

const getJWTToken = (client, payload = {}) => {
  let jwt;
  switch (client) {
    case "admin":
      jwt =
        sessionStorage.getItem("jwtToken") || localStorage.getItem("jwtToken");
      break;
    case "client":
      jwt =
        sessionStorage.getItem("__session") ||
        localStorage.getItem("__session");
      break;
    case "blockchain":
      jwt = issueJwtToken(payload);
      break;

    default:
      break;
  }
  return (jwt || "").replace(/['"]+/g, "");
};

const generateHeaders = (client, options) => {
  if (client === "admin") {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJWTToken(client)}`,
    };
  } else if (client === "blockchain") {
    return {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getJWTToken(client, options)}`,
    };
  } else {
    return {
      "Content-Type": "application/json",
      "X-Authorization": `Bearer ${getJWTToken(client)}`,
    };
  }
};

class Request {
  constructor(client) {
    this.client = client;
  }

  get = (path = "/", options = {}) => {
    return axios(path, {
      headers: generateHeaders(this.client, options),
      ...options,
    });
  };
  post = (path = "/", body = {}, options = {}) =>
    axios.post(path, body, {
      headers: generateHeaders(this.client, options),
      ...options,
    });
  put = (path = "/", body = {}, options = {}) =>
    axios.put(path, body, {
      headers: generateHeaders(this.client, options),
      ...options,
    });
  delete = (path = "/", options = {}) =>
    axios.delete(path, {
      headers: generateHeaders(this.client, options),
      ...options,
    });
}

const requestClient = new Request("client");
const requestAdmin = new Request("admin");
const requestBlockchain = new Request("blockchain");

export { requestClient, requestAdmin, requestBlockchain };