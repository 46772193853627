import React from 'react';

const NotFoundSection = () => {
  return (
    <div>
      Not found!
    </div>
  );
}

export default NotFoundSection;