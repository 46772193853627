module.exports = (texts) => {
  return {
    "inputs": [
      {
        "type": "email",
        "name": "email",
        "placeholder": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_EMAIL"],
        "label": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_EMAIL"],
        "required": true,
        "disabled": true
      },
      {
        "type": "text",
        "name": "name",
        "placeholder": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_FIRST_NAME"],
        "label": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_FIRST_NAME"],
        "required": true,
        "disabled": true
      },
      {
        "type": "text",
        "name": "surname",
        "placeholder": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_SURNAME"],
        "label": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_SURNAME"],
        "required": true,
        "disabled": true
      },
      {
        "type": "text",
        "name": "birthday",
        "placeholder": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_BIRTHDAY"],
        "label": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_BIRTHDAY"],
        "required": true,
        "disabled": true
      },
      {
        "type": "select",
        "name": "gender",
        "placeholder": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_GENDER"],
        "label": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_GENDER"],
        "required": true
      },
      {
        "type": "text",
        "name": "telephone",
        "placeholder": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_TELEPHONE"],
        "label": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_TELEPHONE"],
        "required": true
      },
      {
        "type": "text",
        "name": "mobile",
        "placeholder": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_MOBILE"],
        "label": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_MOBILE"],
        "required": true
      },
      {
        "type": "select",
        "name": "nationality",
        "placeholder": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_NATIONALITY"],
        "label": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_NATIONALITY"],
        "required": true
      },
      {
        "type": "text",
        "name": "documentNumber",
        "placeholder": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_DOCUMENT_NUMBER"],
        "label": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_DOCUMENT_NUMBER"],
        "required": true
      },
      {
        "type": "text",
        "name": "documentType",
        "placeholder": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_DOCUMENT_TYPE"],
        "label": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_DOCUMENT_TYPE"],
        "required": true
      },
      {
        "type": "text",
        "name": "documentIssuedBy",
        "placeholder": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_DOCUMENT_ISSUED_BY"],
        "label": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_DOCUMENT_ISSUED_BY"],
        "required": true
      },
      {
        "type": "text",
        "name": "address.addressLineOne",
        "placeholder": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_ADDRESS_ONE"],
        "label": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_ADDRESS_ONE"],
        "required": true
      },
      {
        "type": "text",
        "name": "address.addressLineTwo",
        "placeholder": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_ADDRESS_TWO"],
        "label": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_ADDRESS_TWO"]
      },
      {
        "type": "text",
        "name": "address.borough",
        "placeholder": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_BOROUGH"],
        "label": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_BOROUGH"],
        "required": true
      },
      {
        "type": "text",
        "name": "address.town",
        "placeholder": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_TOWN"],
        "label": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_TOWN"],
        "required": true
      },
      {
        "type": "text",
        "name": "address.state",
        "placeholder": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_STATE"],
        "label": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_STATE"],
        "required": true
      },
      {
        "type": "text",
        "name": "address.postalCode",
        "placeholder": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_POSTAL_CODE"],
        "label": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_POSTAL_CODE"],
        "required": true
      },
      {
        "type": "text",
        "name": "country",
        "placeholder": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_POSTAL_COUNTRY"],
        "label": texts["HCP_ADD_VACCINE_USER_DETAILS_FORM_POSTAL_COUNTRY"],
        "required": true,
        "disabled": true
      }
    ]
  }
}