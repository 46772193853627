import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React from "react";
import Website from "./Website.jsx";
import { HcpProvider } from "./context/Hcp";
import { TranslationsProvider } from "./context/Translations";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
// import * as serviceWorker from "./serviceWorker";

const root = document.getElementById("root");

const app = (
  <TranslationsProvider>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <HcpProvider>
        <Router>
          <Switch>
            <Route
              path={"/"}
              render={(routerProps) => {
                return <Website {...routerProps} />;
              }}
            />
            <Route
              render={() => {
                return <div> Not found </div>;
              }}
            />
          </Switch>
        </Router>
      </HcpProvider>
    </MuiPickersUtilsProvider>
  </TranslationsProvider>
);

ReactDOM.render(app, root);

// serviceWorker.register();
