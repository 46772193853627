import React, { useContext } from 'react';
import { TranslationsContext } from '../../context/Translations';
import { Avatar, Text } from './Components.js';

const HcpDetails = ({ hcp }) => {

  const {
    translations
  } = useContext(TranslationsContext);

  const { photo, clinic } = hcp;

  const url = photo ? photo.url : '/images/doctor.svg';
  const thumbnailUrl = photo && photo.formats && photo.formats.thumbnail && photo.formats.thumbnail.url;

  return (
    <>
      <Avatar backgroundImage={thumbnailUrl || url} />
      <Text>
        <b>
          {translations['HCP_HOMEPAGE_HCP_DETAILS_NAME']}{' '}
        </b>
        <span>{hcp.name}</span>
      </Text>
      <Text>
        <b>
          {translations['HCP_HOMEPAGE_HCP_DETAILS_SURNAME']}{' '}
        </b>
        <span>{hcp.surname}</span>
      </Text>
      <Text>
        <b>
          {translations['HCP_HOMEPAGE_HCP_DETAILS_TELEPHONE']}{' '}
        </b>
        <span>{hcp.telephone}</span>
      </Text>
      <Text>
        <b>
          {translations['HCP_HOMEPAGE_HCP_DETAILS_MOBILE']}{' '}
        </b>
        <span>{hcp.mobile}</span>
      </Text>
      {clinic && <Text>
        <b>
          {translations['HCP_HOMEPAGE_HCP_DETAILS_CLINIC']}{' '}
        </b>
        <span>
          {clinic.commercialName}{' '},
          {clinic.address && clinic.address.addressLineOne}{' '}
          {clinic.address && clinic.address.addressLineTwo}{' '}
          {clinic.address && clinic.address.borough}{' '}
          {clinic.address && clinic.address.town}{' '}
          {clinic.address && clinic.address.state}{' '}
          {clinic.address && clinic.address.postalCode}{' '}
        </span>
      </Text>}
    </>
  )

}

export default HcpDetails;