import React, { useState, useContext } from "react";
import {
  Form,
  StyledTitle,
  Button,
  Message,
  StyledLink,
  Text,
} from "./Components";
import Input from "../Input/Input";
import axios from "axios";
import { TranslationsContext } from "../../context/Translations";
import { validateEmail } from "../../utils/";

const ForgotPassForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [emailData, setEmailData] = useState({
    value: "",
    touched: false,
    error: "",
  });

  const { translations, selectedLang } = useContext(TranslationsContext);

  const onSubmit = async (e) => {
    try {
      e.preventDefault();

      const isValid = validateEmail(emailData.value);

      if (!isValid) {
        setEmailData({
          ...emailData,
          error: translations["HCP_AUTH_FORM_INVALID_EMAIL"],
          touched: true,
        });
        return;
      } else {
        setEmailData({ ...emailData, error: "", touched: true });
      }

      await axios.post(`/hcps/forgot-password?lang=${selectedLang}`, {
        email: emailData.value,
      });

      setSubmitted(true);
    } catch (e) {
      const { isAxiosError } = e;

      let message = translations["HCP_AUTH_FORM_SOMETHING_WENT_WRONG"];

      if (isAxiosError) {
        message =
          typeof e.response.data.message === "string"
            ? e.response.data.message
            : message;
      }

      setMessage(message);
    }
  };

  return submitted ? (
    <Text>{translations["HCP_FORGOTPASSPAGE_SUBMIT_MESSAGE"]}</Text>
  ) : (
    <>
      <Form onSubmit={onSubmit}>
        <StyledTitle htmlFor={"email-forgot-pass"}>
          {translations["HCP_FORGOTPASSPAGE_FORGOTPASS_DETAILS"]}
        </StyledTitle>
        <Input
          id="email-forgot-pass"
          type="email"
          placeholder={translations["HCP_FORGOTPASSPAGE_EMAIL_PLACEHOLDER"]}
          label={translations["HCP_FORGOTPASSPAGE_EMAIL_PLACEHOLDER"]}
          error={emailData.touched && emailData.error !== ""}
          onChange={({ target: { value } }) => {
            setEmailData({ ...emailData, value });
          }}
          required
        />
        <Message>{emailData.touched && emailData.error}</Message>
        <Button type="submit">
          {translations["HCP_FORGOTPASSPAGE_SUBMIT_BUTTON"]}
        </Button>
      </Form>
      <Message general>{message}</Message>
    </>
  );
};

export default ForgotPassForm;
