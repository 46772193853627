import React, { useState, useContext } from "react";
import moment from "moment";
import axios from "axios";
import filesize from "filesize";
import hcpApprovedOnly from "../../HOC/HcpApprovedOnly";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TranslationsContext } from "../../context/Translations";
import { HcpContext } from "../../context/Hcp";
import { Page, Header, InputWrapper, Button, PageBody } from "./Components";
import Input from "../../components/Input/Input";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { request, configEnv } from "../../utils";
import Upload from "../../components/Upload";
import DatePicker from "../../components/DatePicker";
import FileList from "../../components/FileList";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
  uploadArea: {
    flexGrow: 1,
  },
  border: {
    borderBottom: "2px solid lightgray",
    width: "100%",
  },
  content: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontWeight: 500,
    fontSize: 22,
    color: "lightgray",
    textAlign: "center",
  },
}));

const DividerWithText = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.border} />
      <span className={classes.content}>{children}</span>
      <div className={classes.border} />
    </div>
  );
};

const DigitalCertificate = () => {
  const classes = useStyles();
  const { translations } = useContext(TranslationsContext);

  const [code, setCode] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [userConfirmed, setUserConfirmed] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [examNames, setExamNames] = useState([]);
  const [selectedExamName, setSelectedExamName] = useState();
  const [selectedExamResult, setSelectedExamResult] = useState(
    translations["EXAM_RESULT_MEDICAL_REPORT"]
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [user, setUser] = useState(null);
  const [selectedDate, setSelectedDate] = React.useState(moment());

  const { state: hcpstate } = useContext(HcpContext);
  const examResults = [
    translations["EXAM_RESULT_MEDICAL_REPORT"],
    translations["EXAM_RESULT_POSITIVE"],
    translations["EXAM_RESULT_NEGATIVE"],
  ];

  const fetchUserAndDocuments = async () => {
    try {
      setIsLoading(true);
      setError(false);
      const { data } = await request.get(`/hcps/user?code=${code}`);
      const { data: examNamesData } = await request.get("/exam-names");
      setExamNames(examNamesData);
      setSelectedExamName({});
      setUser(data);
    } catch (e) {
      setError(true);
      setUser(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpload = (files) => {
    if (files.length > 1) {
      alert(translations["DOCUMENT_UPLOAD_AREA_ONE_FILE_WARNING"]);
      return;
    }

    if (!selectedExamName.name) {
      alert(translations["UPLOAD_AREA_SELECT_NAME_WARNING"]);
      return;
    }

    const uploadedFilesDrop = files.map((file) => ({
      file,
      id: uploadedFiles.length + 1,
      name: `${selectedExamName.name}.pdf`,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    setUploadedFiles((uploadedFiles) => [
      ...uploadedFiles,
      ...uploadedFilesDrop,
    ]);

    uploadedFilesDrop.forEach(processUpload);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const updateFile = (id, data) => {
    setUploadedFiles((uploadedFiles) =>
      uploadedFiles.map((uploadedFile) => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile;
      })
    );
  };

  const processUpload = (uploadedFile) => {
    const data = new FormData();

    data.append("file", uploadedFile.file, uploadedFile.name);
    data.append("hcpId", hcpstate.data._id);
    data.append("nameId", selectedExamName._id);
    data.append("user", JSON.stringify(user.hashInfo.user));
    data.append("examCollectDate", selectedDate);
    data.append("examResult", selectedExamResult);

    updateFile(uploadedFile.id, {
      uploaded: true,
    });
    alert(`File: ${uploadedFile.name} successfully uploaded`);
    setSelectedExamName({});
    // axios
    //   .post(`${configEnv.REACT_APP_HASH_API}/hash-register/exam`, data, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //     onUploadProgress: (progressEvent) => {
    //       let progress = parseInt(
    //         Math.round((progressEvent.loaded * 100) / progressEvent.total)
    //       );

    //       updateFile(uploadedFile.id, {
    //         progress,
    //       });
    //     },
    //   })
    //   .then((response) => {
    //     updateFile(uploadedFile.id, {
    //       uploaded: true,
    //     });
    //     alert(`File: ${uploadedFile.name} successfully uploaded`);
    //     setSelectedExamName({});
    //   })
    //   .catch((err) => {
    //     updateFile(uploadedFile.id, {
    //       error: true,
    //       errorMessage:
    //         translations[`FILE_UPLOAD_ERROR_CODE_${err.response.status}`],
    //     });
    //     setSelectedExamName({});
    //   });
  };

  const onCodeChange = ({ target: { value } }) => {
    if (value.length < 6) {
      setCode(value);
      setButtonDisabled(true);
    } else if (value.length === 6) {
      setCode(value);
      setButtonDisabled(false);
    }
  };

  return (
    <Page>
      <Header>
        <InputWrapper>
          <Input
            label={translations["HCP_ADD_VACCINE_LABEL_ACCESS_CODE"]}
            placeholder={
              translations["HCP_ADD_VACCINE_LABEL_ACCESS_CODE_PLACEHOLDER"]
            }
            name={"accessCode"}
            value={code}
            onChange={onCodeChange}
            required
          />
        </InputWrapper>
        <Button
          onClick={fetchUserAndDocuments}
          disabled={buttonDisabled}
          loading={isLoading.toString()}
        >
          {translations["HCP_ADD_VACCINE_FIND_USER"]}
        </Button>
      </Header>
      <PageBody appear={error || !!user}>
        {error ? (
          <span>{translations["HCP_ADD_VACCINE_NOT_FIND_USER"]}</span>
        ) : user ? (
          user.details.plan ? (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  <span style={{ fontWeight: "bold" }}>
                    {translations["USER_PROFILEPAGE_NAME"]}:
                  </span>{" "}
                  {user.details.name + " " + user.details.surname}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  <span style={{ fontWeight: "bold" }}>
                    {translations["USER_PROFILEPAGE_DOCUMENT_TYPE"]}:
                  </span>{" "}
                  {user.details.documentType}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  <span style={{ fontWeight: "bold" }}>
                    {translations["USER_PROFILEPAGE_DOCUMENT_NUMBER"]}:
                  </span>{" "}
                  {user.details.documentNumber}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  <span style={{ fontWeight: "bold" }}>
                    {translations["USER_PROFILEPAGE_BIRTHDAY"]}:
                  </span>{" "}
                  {user.details.birthday}
                </Typography>
              </Grid>
              <Grid container justify="flex-end">
                <Button
                  disabled={userConfirmed}
                  onClick={() => setUserConfirmed(true)}
                >
                  {translations["CHECKOUT_AREA_CONFIRM_BUTTON_LABEL"]}
                </Button>
              </Grid>
              {userConfirmed ? (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <DividerWithText>
                        {translations["DOCUMENT_UPLOAD_AREA_DIVIDER_TEXT"]}
                      </DividerWithText>
                    </Grid>
                    <Grid item xs={12} container spacing={3}>
                      <Grid item xs={8} container spacing={3}>
                        <Grid item xs={12}>
                          <Autocomplete
                            id="select-exam-combo"
                            value={selectedExamName}
                            onChange={(_, newValue) => {
                              setSelectedExamName(newValue);
                            }}
                            options={examNames}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={
                                  translations[
                                    "DOCUMENT_UPLOAD_AREA_EXAM_LABEL"
                                  ]
                                }
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{ alignSelf: "center", marginTop: "8px " }}
                        >
                          <Autocomplete
                            id="select-exam-result"
                            value={selectedExamResult}
                            onChange={(_, newValue) => {
                              setSelectedExamResult(newValue);
                            }}
                            options={examResults}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={
                                  translations[
                                    "DOCUMENT_UPLOAD_AREA_EXAM_RESULT_LABEL"
                                  ]
                                }
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <DatePicker
                            date={selectedDate}
                            handleDateChange={handleDateChange}
                            translations={translations}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs container direction="column">
                        <Grid item xs={12}>
                          <Upload
                            onUpload={handleUpload}
                            translations={translations}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {!!uploadedFiles.length && (
                      <FileList
                        translations={translations}
                        files={uploadedFiles}
                      />
                    )}
                  </Grid>
                </>
              ) : (
                ""
              )}
            </Grid>
          ) : null
        ) : null}
      </PageBody>
    </Page>
  );
};

export default hcpApprovedOnly(DigitalCertificate);
