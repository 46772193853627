import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 3;
`

const Text = styled.h1`
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  max-width: 769px;
  text-align: center;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
  padding: 18px;
  background-color: #FFFFFF;
  border-radius: 5px;
`

export { Wrapper, Text };