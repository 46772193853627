import styled from 'styled-components';

const Background = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
`

const BlurredEffect = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center 0;
  filter: blur(4px);
  z-index: 2;
`

export { Background, BlurredEffect };