import React, { useState, useContext } from "react";
import { Vaccines, Card, CardTitle } from "./Component";
import CardItemTranscription from "../CardItemTranscription/CardItemTranscription";
import UserDetails from "../UserDetails/UserDetails";
import { TranslationsContext } from "../../context/Translations";

const timeUnitByDays = {
  week: 7,
  month: 30,
  year: 360,
};

const VaccineCardTranscription = ({ user, refreshUser }) => {
  if (!user) {
    return null;
  }

  const [summary, showSummary] = useState(true);

  const { vaccines, details } = user;

  const { translations } = useContext(TranslationsContext);

  return (
    <Card>
      <UserDetails
        details={details}
        summary={summary}
        showSummary={showSummary}
        refreshUser={refreshUser}
      />
      {summary && (
        <Vaccines>
          {(vaccines || []).length > 0 && (
            <CardTitle>
              {translations["HCP_ADD_VACCINE_CARD_TITLE_VACCINE"]}
            </CardTitle>
          )}
          {(vaccines || [])
            .sort((a, b) => {
              try {
                return (
                  a["applicationStart"] *
                    timeUnitByDays[a["applicationStartUnit"]] -
                  b["applicationStart"] *
                    timeUnitByDays[b["applicationStartUnit"]]
                );
              } catch (_) {
                return 0;
              }
            })
            .map((item) => {
              return (
                <CardItemTranscription
                  vaccineName={item.name}
                  vaccineId={item.id}
                  key={item.id}
                  applied={!item.due}
                  userId={details.id}
                  vaccineType="vaccine"
                  hcpName={item.hcpFullName}
                  hcpNumber={item.hcpRegistryNumber}
                  vaccineLotNumber={item.lotNumber}
                  date={item.date}
                  producerName={item.producer}
                  wasTranscribed={item.wasTranscribed}
                  transcription={item.transcription}
                  vaccBrandName={item.brandName}
                  refreshUser={refreshUser}
                />
              );
            })}
        </Vaccines>
      )}
    </Card>
  );
};

export default VaccineCardTranscription;
