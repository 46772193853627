import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useLoginForm } from '../../hooks';
import { Form, StyledTitle, Button, Message, StyledLink, TextButton } from './Components';
import Input from '../Input/Input';
import axios from 'axios';
import { HcpContext } from '../../context/Hcp';
import { TranslationsContext } from '../../context/Translations';

const LoginForm = () => {

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const { dispatch } = useContext(HcpContext);
  const { translations } = useContext(TranslationsContext)
  const { insertField, errors, touched, values } = useLoginForm(translations);

  const isThereErrors = Object.values(errors).filter(error => error !== '').length > 0;

  const onSubmit = async (e) => {
    try {
      e.preventDefault();

      setMessage('');

      if (isThereErrors) return;

      setLoading(true);

      const { data } = await axios.post('/hcps/login', {
        email: values.email,
        password: values.password,
      });

      if (data.ok) {
        localStorage.setItem('__session', data.jwt);
        dispatch('login', data.hcp)
      }

    } catch (e) {

      const { isAxiosError } = e;

      let message = translations["HCP_AUTH_FORM_SOMETHING_WENT_WRONG"];

      if (isAxiosError) {
        message = typeof e.response.data.message === 'string' ? e.response.data.message : message;
      }

      setMessage(message);
      setLoading(false);
    }

  };

  return (
    <>
      <Form onSubmit={onSubmit}>
        <StyledTitle htmlFor={'email-login'}>
          {translations["HCP_LOGIN_PAGE_TITLE"]}
        </StyledTitle>
        <Input
          id='email-login'
          type='email'
          placeholder={translations["HCP_LOGIN_PAGE_EMAIL"]}
          label={translations["HCP_LOGIN_PAGE_EMAIL"]}
          error={touched['email'] && errors['email'] !== ''}
          onChange={({ target: { value } }) => {
            insertField('email', value);
          }}
          required
        />
        <Message>{touched['email'] && errors['email']}</Message>
        <Input
          id='password-login'
          type='password'
          placeholder={translations["HCP_LOGIN_PAGE_PASSWORD"]}
          label={translations["HCP_LOGIN_PAGE_PASSWORD"]}
          error={touched['password'] && errors['password'] !== ''}
          onChange={({ target: { value } }) => {
            insertField('password', value);
          }}
          required
        />
        <Message>{touched['password'] && errors['password']}</Message>
        <Button type="submit">
          {translations["HCP_LOGIN_PAGE_LOGIN"]}
        </Button>
        <StyledLink to={'/register'}>
          {translations["HCP_LOGIN_PAGE_REGISTER"]}
        </StyledLink>
        <Link to="/forgot-password">
          <TextButton marginTop="20px">
            {translations['HCP_LOGIN_PAGE_FORGOT_PASS']}
          </TextButton>
        </Link>
      </Form>
      <Message general>{message}</Message>
    </>
  )
}

export default LoginForm;