module.exports = (texts) => {
  return {
    "inputs": [
      {
        "type": "text",
        "name": "name",
        "placeholder": texts["HCP_REGISTER_PAGE_NAME"],
        "label": texts["HCP_REGISTER_PAGE_NAME"],
        "required": true
      },
      {
        "type": "text",
        "name": "surname",
        "placeholder": texts["HCP_REGISTER_PAGE_SURNAME"],
        "label": texts["HCP_REGISTER_PAGE_SURNAME"],
        "required": true
      },
      {
        "type": "email",
        "name": "email",
        "placeholder": texts["HCP_REGISTER_PAGE_EMAIL"],
        "label": texts["HCP_REGISTER_PAGE_EMAIL"],
        "required": true
      },
      {
        "type": "text",
        "name": "telephone",
        "placeholder": texts["HCP_REGISTER_PAGE_TELEPHONE"],
        "label": texts["HCP_REGISTER_PAGE_TELEPHONE"],
        "required": true
      },
      {
        "type": "text",
        "name": "mobile",
        "placeholder": texts["HCP_REGISTER_PAGE_MOBILE"],
        "label": texts["HCP_REGISTER_PAGE_MOBILE"],
        "required": true
      },
      {
        "type": "text",
        "name": "linkedinProfile",
        "placeholder": texts["HCP_REGISTER_PAGE_LINKEDIN"],
        "label": texts["HCP_REGISTER_PAGE_LINKEDIN"]
      },
      {
        "type": "password",
        "name": "password",
        "placeholder": texts["HCP_REGISTER_PAGE_PASSWORD"],
        "label": texts["HCP_REGISTER_PAGE_PASSWORD"],
        "required": true
      },
      {
        "type": "password",
        "name": "confirmPassword",
        "placeholder": texts["HCP_REGISTER_PAGE_PASSWORD_CONFIRM"],
        "label": texts["HCP_REGISTER_PAGE_PASSWORD_CONFIRM"],
        "required": true
      },
      {
        "type": "text",
        "name": "registryNumber",
        "placeholder": texts["HCP_REGISTER_PAGE_REGISTRY"],
        "label": texts["HCP_REGISTER_PAGE_REGISTRY"],
        "required": true
      }
    ]
  }
}