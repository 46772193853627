import React, { useContext, useState } from 'react';
import { HcpContext } from '../../context/Hcp';
import { TranslationsContext } from '../../context/Translations';
import hcpApprovedOnly from '../../HOC/HcpApprovedOnly';
import Input from '../../components/Input/Input';
import config from './config.js';
import { Page, Form, InputWrapper, Title, Button, ErrorMessage } from './Components';
import Avatar from '../../components/Avatar/Avatar';
import { request, getInputValuesFromForm } from '../../utils/';

const ProfilePage = ({ history }) => {

  const { state: hcpState, dispatch } = useContext(HcpContext);
  const { translations } = useContext(TranslationsContext);

  const [loading, setLoading] = useState({
    avatar: false,
    personal: false,
    password: false,
  })

  const [errors, setErrors] = useState({})

  const { data: hcpData = {} } = hcpState;

  const {
    photo,
    _id
  } = hcpData;

  const avatarSrc = photo ? photo.url : null;

  const thumbnailUrl = photo && photo.formats && photo.formats.thumbnail && photo.formats.thumbnail.url;

  const { personal, password } = config(translations);

  const buildFields = (fields) => {
    return fields.map((input, index) => {

      const { name } = input;
      const { data } = hcpState;

      return (
        <InputWrapper key={`${name}-${index}`}>
          <Input defaultValue={data[name]} {...input} />
        </InputWrapper>
      )
    })
  }

  const personalDataFields = buildFields(personal);
  const passwordDataFields = buildFields(password);

  const redirectToFeedback = (feedback) => {
    history.push({
      pathname: '/dashboard/success-feedback',
      state: {
        message: feedback
      }
    })
  }

  const avatarHandler = async (file) => {
    try {
      setLoading({
        ...loading,
        avatar: true
      })

      const formData = new FormData();

      formData.append('file.photo', file);

      const { data: { updatedInfo } } = await request.put(`/hcps/${_id}?section=avatar`, formData);

      const newHcpData = {
        ...hcpData,
        photo: updatedInfo
      };

      setLoading({
        ...loading,
        avatar: false
      })

      dispatch('update', newHcpData);
    } catch (_) {
      //
    }
  }

  const dataHandler = async (e, section, feedback) => {
    try {

      setLoading({
        ...loading,
        [section]: true
      })

      setErrors({})

      const values = getInputValuesFromForm(e, ['text', 'password']);

      if (section === 'password') {
        validatePassword(values)
      }

      const { data: { updatedInfo } } = await request.put(`/hcps/${_id}?section=${section}`, values);

      const newHcpData = {
        ...hcpData,
        ...updatedInfo
      };

      dispatch('update', newHcpData);

      redirectToFeedback(feedback)

    } catch (e) {

      let errorMessage = '';

      const { isAxiosError } = e;

      if (isAxiosError) {
        errorMessage = typeof e.response.data.message === 'string' ? e.response.data.message : e.message;
      } else {
        errorMessage = e.message
      }

      setErrors({
        [section]: errorMessage,
      })

    }
  }

  const validatePassword = ({ newPassword, confirmNewPassword }) => {
    if (newPassword !== confirmNewPassword) {
      throw new Error(translations["HCP_PROFILE_PAGE_PASSWORD_NOT_MATCH"])
    }
  }

  return (
    <Page>
      <Avatar
        src={thumbnailUrl || avatarSrc}
        onChange={avatarHandler}
        loading={loading['avatar']}
      />
      <Title> {translations["HCP_PROFILE_PAGE_PERSONAL_DATA"]} </Title>
      <Form id='personal-data-form' onSubmit={(e) => dataHandler(e, 'personal', translations["HCP_PROFILE_PAGE_PERSONAL_DATA_SUCCESS_FEEDBACK"])}>
        {personalDataFields}
        <Button form={'personal-data-form'} loading={loading['personal'] ? loading['personal'] : undefined}>
          {loading['personal'] ? 'Loading...' : translations["HCP_PROFILE_PAGE_UPDATE_BUTTON"]}
        </Button>
        <ErrorMessage>{errors['personal']}</ErrorMessage>
      </Form>
      <Title> {translations["HCP_PROFILE_PAGE_PASSWORD_TITLE"]} </Title>
      <Form id={'personal-password-form'} onSubmit={(e) => dataHandler(e, 'password', translations["HCP_PROFILE_PAGE_PASSSWORD_SUCCESS_FEEDBACK"])}>
        {passwordDataFields}
        <Button form={'personal-password-form'} loading={loading['password'] ? loading['password'] : undefined}>
          {loading['password'] ? 'Loading...' : translations["HCP_PROFILE_PAGE_PASSWORD_UPDATE"]}
        </Button>
        <ErrorMessage>{errors['password']}</ErrorMessage>
      </Form>
    </Page>
  )
}

export default hcpApprovedOnly(ProfilePage);