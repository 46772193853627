import React, { useContext } from "react";
import {
  Menu,
  Header,
  StyledLink,
  Label,
  Button,
  Logo,
  ClinicLogo,
  Footer,
} from "./Components";
import { generateKeys, buildUrl } from "../../utils/";
import Icon from "../Icon/Icon";
import PropTypes from "prop-types";
import { logout } from "../../utils/";
import { HcpContext } from "../../context/Hcp";
import { TranslationsContext } from "../../context/Translations";

const keys = generateKeys(7);

const LeftMenu = ({ path, pathname, show = false, showMenu, history }) => {
  const {
    dispatch,
    state: { data: hcpState },
  } = useContext(HcpContext);
  const { translations } = useContext(TranslationsContext);

  const LINKS = [
    {
      to: "/",
      label: translations["HCP_LEFT_MENU_HOME"],
      key: keys[0],
      icon: <Icon icon="home" height={"24px"} width={"24px"} />,
    },
    {
      to: "/profile",
      label: translations["HCP_LEFT_MENU_MY_PROFILE"],
      key: keys[1],
      icon: <Icon icon="account" height={"24px"} width={"24px"} />,
    },
    {
      to: "/users",
      label: translations["HCP_LEFT_MENU_ADD_USER"],
      key: keys[2],
      icon: <Icon icon="person-add" height={"24px"} width={"24px"} />,
    },
    {
      to: "/vaccines",
      label: translations["HCP_LEFT_MENU_APPLY_VACCINE"],
      key: keys[3],
      icon: <Icon icon="syringe" height={"24px"} width={"24px"} />,
    },
    {
      to: "/transcribe-records",
      label: translations["HCP_HOMEPAGE_TRANSCRIBE_RECORDS"],
      key: keys[4],
      icon: <Icon icon="file" height={"24px"} width={"24px"} />,
    },
    {
      to: "/digital-certificates",
      label: translations["HCP_HOMEPAGE_DIGITAL_CERTIFICATES"],
      key: keys[5],
      icon: <Icon icon="badge" height={"24px"} width={"24px"} />,
    },
  ];

  const index = LINKS.findIndex((link) => {
    if (link.to !== "/") {
      return pathname.includes(link.to);
    }
  });

  const { certificate, clinic, name, surname } = hcpState;
  const selectedIndex = index < 0 ? 0 : index;

  const professional = `${(certificate && certificate.prefix) || ""} ${
    name || ""
  } ${surname || ""}`;
  const clinicLogo = (clinic && clinic.logo && clinic.logo.url) || "";

  return (
    <Menu show={show}>
      <Header>
        {clinicLogo && <ClinicLogo src={clinicLogo} />}
        <Label
          style={{
            fontSize: !clinicLogo ? "16px" : "13px",
            lineHeight: !clinicLogo ? "21px" : "16px",
          }}
        >
          {professional}
        </Label>
      </Header>
      {LINKS.map((link, i) => {
        const { icon, label, to, key } = link;
        const url = buildUrl([path, to]);
        return (
          <StyledLink
            key={key}
            to={url}
            selected={i === selectedIndex}
            onClick={() => showMenu(false)}
          >
            {icon}
            <Label>{label}</Label>
          </StyledLink>
        );
      })}
      <Button
        onClick={() => {
          const didLogout = logout(
            history,
            translations["HCP_LEFT_MENU_LOGOUT_CONFIRMATION"]
          );
          if (didLogout) {
            dispatch("logout");
          }
        }}
      >
        <span>{translations["HCP_LEFT_MENU_LOGOUT"]}</span>
        <Icon
          icon="logout"
          height={"24px"}
          width={"24px"}
          style={{
            fill: "#FFFFFF",
            marginLeft: "8px",
            transform: "rotate(180deg)",
          }}
        />
      </Button>
      <Footer>
        powered by
        <Logo src={"/brand.svg"} style={{width: 60 }} />
      </Footer>
    </Menu>
  );
};

LeftMenu.propTypes = {
  path: PropTypes.string,
  pathname: PropTypes.string,
  showMenu: PropTypes.func,
  show: PropTypes.bool,
};

export default LeftMenu;
