import React, { useState, useEffect, useContext } from 'react';
import { useRegisterForm } from '../../hooks';
import {
  Form,
  StyledTitle,
  Button,
  Message,
  StyledLink,
  UploaderContainer
} from './Components';
import Input from '../Input/Input';
import Select from '../Select/Select';
import FileUploader from '../FileUploader/FileUploader';
import axios from 'axios';
import config from './config.js';
import { HcpContext } from '../../context/Hcp';
import { TranslationsContext } from '../../context/Translations';

const RegisterForm = () => {

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [formData, setFormData] = useState([]);
  const [certificatesData, setCertificatesData] = useState([]);
  const [clinicsData, setClinicsData] = useState([])
  const [resetClinic, setResetClinic] = useState(0)

  const { dispatch } = useContext(HcpContext);
  const { translations } = useContext(TranslationsContext);
  const { insertField, errors, touched, values } = useRegisterForm(translations);

  const isThereErrors = Object.values(errors).filter(error => error !== '').length > 0;
  const everyFieldTouched = Object.values(touched).filter(field => field === false).length === 0;

  useEffect(() => {
    const getFormData = async () => {
      try {
        const { data } = await axios.get('/hcps/form-data');
        console.log("form-data:", data)
        setFormData(data);
      } catch (e) {
        //
      }
    }
    getFormData();
  }, [])

  useEffect(() => {
    (async () => {
      const foundCountry = (formData || []).find(data => {
        const { _id } = data;
        return _id === values['country'];
      }) || {};

      const certificatesFromCountry = foundCountry['certificates'];
      setCertificatesData(certificatesFromCountry)

      let clinicsDataFromCountry = []

      if (foundCountry._id) {
        const { data } = await axios.get(`/hcps/clinics?countryId=${foundCountry._id}`)
        clinicsDataFromCountry = data;
      }

      setClinicsData(clinicsDataFromCountry)

      if (touched['certificate']) insertField('certificate', '')

      setResetClinic(resetClinic + 1)

    })()
  }, [values['country']])

  useEffect(() => {
    if (touched['clinic']) insertField('clinic', '')
  }, [resetClinic])



  const onSubmit = async (e) => {
    try {

      e.preventDefault();

      if (isThereErrors || !everyFieldTouched) return;

      setLoading(true);

      const formDataToSend = new FormData()

      const {
        documentBack,
        documentFront,
        professionalRegistrationFront,
        professionalRegistrationBack,
        ...rest
      } = values;

      documentBack && formDataToSend.append('file.documentBack', documentBack, documentBack.name);
      documentFront && formDataToSend.append('file.documentFront', documentFront, documentFront.name);
      professionalRegistrationFront && formDataToSend.append('file.professionalRegistrationFront', professionalRegistrationFront, professionalRegistrationFront.name);
      professionalRegistrationBack && formDataToSend.append('file.professionalRegistrationBack', professionalRegistrationBack, professionalRegistrationBack.name);

      if (rest['clinic'] === '') delete values['clinic']

      formDataToSend.append('data', JSON.stringify(rest));

      const { data } = await axios.post('/hcps/register', formDataToSend);

      if (data.ok) {
        localStorage.setItem('__session', data.jwt);
        dispatch('login', data.hcp)
      }

    } catch (e) {

      console.log('e', e);

      const { isAxiosError } = e;

      let message = translations["HCP_AUTH_FORM_SOMETHING_WENT_WRONG"];

      if (isAxiosError) {
        message = typeof e.response.data.message === 'string' ? e.response.data.message : message;
      }

      setMessage(message);
      setLoading(false);
    }

  };

  const { inputs } = config(translations);

  const formInputs = inputs.map((inputProps, index) => {

    const { name } = inputProps;

    return (
      <React.Fragment key={`${name}-register-${index}`}>
        <Input
          {...inputProps}
          id={`${name}-register`}
          error={touched[name] && errors[name] !== '' && errors[name] !== undefined}
          onBlur={({ target: { value } }) => {
            insertField(name, value);
          }}
        />
        <Message>{touched[name] && errors[name]}</Message>
      </React.Fragment>
    )
  })

  return (
    <>
      <Form onSubmit={onSubmit}>
        <StyledTitle htmlFor={'email-login'}>
          {translations["HCP_REGISTER_PAGE_TITLE"]}
        </StyledTitle>
        {formInputs.slice(0, formInputs.length - 1)}
        <Select
          key={'country-customized-select-label'}
          label={translations["HCP_REGISTER_PAGE_FORM_COUNTRY"]}
          labelId={'country-customized-select-label'}
          id={'country-customized-select'}
          error={touched['country'] && errors['country'] !== '' && errors['country'] !== undefined}
          placeholder={translations["HCP_REGISTER_PAGE_FORM_COUNTRY"]}
          value={values['country'] || ''}
          onChange={(value) => {
            insertField('country', value);
          }}
          fallbackMessage={translations["HCP_REGISTER_PAGE_FORM_COUNTRY_NO_OPTIONS"]}
          options={(formData || []).map(data => {
            const { _id, populated_country: { country: { name } } } = data;
            return { value: _id, label: name }
          })}
          required
          errorMessage={touched['country'] && errors['country']}
        />
        <Select
          key={'certificate-customized-select-label'}
          label={translations["HCP_REGISTER_PAGE_FORM_CERTIFICATE"]}
          labelId={'certificate-customized-select-label'}
          id={'certificate-customized-select'}
          error={touched['certificate'] && errors['certificate'] !== '' && errors['certificate'] !== undefined}
          placeholder={translations["HCP_REGISTER_PAGE_FORM_CERTIFICATE"]}
          value={values['certificate'] || ''}
          onChange={(value) => {
            insertField('certificate', value);
          }}
          fallbackMessage={touched['country'] ? translations["HCP_REGISTER_PAGE_FORM_CERTIFICATE_AND_CLINIC_NO_OPTIONS"] : translations["HCP_REGISTER_PAGE_FORM_CERTIFICATE_AND_CLINIC_SELECT_COUNTRY"]}
          options={(certificatesData || []).map(certificate => {
            const { _id, title } = certificate;
            return { value: _id, label: title }
          })}
          required
          errorMessage={touched['certificate'] && errors['certificate']}
        />
        <Select
          key={'business-customized-select-label'}
          label={translations["HCP_REGISTER_PAGE_FORM_CLINIC"]}
          labelId={'business-customized-select-label'}
          id={'business-customized-select'}
          error={touched['clinic'] && errors['clinic'] !== '' && errors['clinic'] !== undefined}
          placeholder={translations["HCP_REGISTER_PAGE_FORM_CLINIC"]}
          value={values['clinic'] || ''}
          onChange={(value) => {
            insertField('clinic', value);
          }}
          fallbackMessage={touched['country'] ? translations["HCP_REGISTER_PAGE_FORM_CERTIFICATE_AND_CLINIC_NO_OPTIONS"] : translations["HCP_REGISTER_PAGE_FORM_CERTIFICATE_AND_CLINIC_SELECT_COUNTRY"]}
          required
          options={(clinicsData || []).map(clinic => {
            const { _id, name } = clinic;
            return { value: _id, label: name }
          })}
          errorMessage={touched['clinic'] && errors['clinic']}
        />
        {formInputs.slice(formInputs.length - 1, formInputs.length)}
        <UploaderContainer>
          <FileUploader
            label={translations["HCP_REGISTER_PAGE_DOCUMENT_ID_FRONT"]}
            onChange={(file) => { insertField('documentFront', file) }}
          />
          <FileUploader
            label={translations["HCP_REGISTER_PAGE_DOCUMENT_ID_BACK"]}
            onChange={(file) => { insertField('documentBack', file) }}
          />
          <FileUploader
            label={translations["HCP_REGISTER_PAGE_DOCUMENT_CERTIFICATE_FRONT"]}
            onChange={(file) => { insertField('professionalRegistrationFront', file) }}
          />
          <FileUploader
            label={translations["HCP_REGISTER_PAGE_DOCUMENT_CERTIFICATE_FRONT"]}
            onChange={(file) => { insertField('professionalRegistrationBack', file) }}
          />
        </UploaderContainer>
        <Button type="submit" loading={isLoading.toString()}>
          {translations["HCP_REGISTER_PAGE_REGISTER"]}
        </Button>
        <StyledLink to={'/login'}>
          {translations["HCP_REGISTER_PAGE_LOGIN"]}
        </StyledLink>
      </Form>
      <Message general>{message}</Message>
    </>
  )
}

export default RegisterForm;