import React, { useRef } from 'react';
import { Wrapper, HeaderEffect, Img, Logo } from './Components';
import SelectLangs from '../SelectLangs/SelectLangs';

const AuthPageWrapper = ({ form, bgImgSrc }) => {
  const Form = form;

  const imgRef = useRef(null);

  const onLoad = () => {
    try {
      const { current } = imgRef;
      current.style.marginTop = '-62px'
    } catch (_) {
      //
    }
  }

  try {
    return (
      <Wrapper>
        <SelectLangs />
        <HeaderEffect />
        <Logo src={'/brand.svg'} style={{width: 80}}/>
        {bgImgSrc && <Img src={bgImgSrc} ref={imgRef} onLoad={onLoad} />}
        <Form />
      </Wrapper>
    );
  } catch (e) {
    console.log(e);
    return null;
  }
};


export default AuthPageWrapper;
