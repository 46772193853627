import styled from 'styled-components';

const Page = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
`

const Button = styled.button`
  background-color: #3298DC;
  color: #FFF;
  font-weight: bold;
  font-size: 16px;
  margin: 12px 0px;
  padding: 6px 12px;
  min-width: 140px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`

export { Page, Button }