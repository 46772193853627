import React, { useEffect, useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import RoutesBuilder from './components/RoutesBuilder/RoutesBuilder';
import NotFoundSection from './components/NotFoundSection/NotFoundSection';
import RenderWhen from './components/RenderWhen/RenderWhen';
import LoginPage from './containers/LoginPage/LoginPage';
import RegisterPage from './containers/RegisterPage/RegisterPage';
import ForgotPassPage from './containers/ForgotPassPage/ForgotPassPage';
import ConfirmNewPassword from './containers/ConfirmNewPassword/ConfirmNewPassword';

import Dashboard from './containers/Dashboard/Dashboard';

import { request, generateKeys } from './utils';
import { HcpContext } from './context/Hcp';

import './index.css';
const keys = generateKeys(5);

const Website = (props) => {

  const { state: hcpState, dispatch } = useContext(HcpContext);

  const { isAuth } = hcpState;

  const ROUTES = [
    {
      path: '/',
      exact: true,
      key: keys[0],
      component: () => {
        return (
          <RenderWhen
            when={isAuth}
            componentToRender={() => <Redirect to={'/dashboard'} />}
            redirectComponent={() => <Redirect to={'/login'} />}
          />
        )
      }
    },
    {
      path: '/login',
      exact: true,
      key: keys[1],
      component: (props) => {
        return (
          <RenderWhen
            when={isAuth === undefined ? undefined : isAuth === true ? false : true}
            componentToRender={() => <LoginPage {...props} />}
            redirectComponent={() => <Redirect to={'/dashboard'} />}
          />
        )
      }
    },
    {
      path: '/register',
      exact: true,
      key: keys[2],
      component: (props) => {
        return (
          <RenderWhen
            when={isAuth === undefined ? undefined : isAuth === true ? false : true}
            componentToRender={() => <RegisterPage {...props} />}
            redirectComponent={() => <Redirect to={'/dashboard'} />}
          />
        )
      }
    },
    {
      path: '/forgot-password',
      exact: true,
      key: keys[3],
      component: (props) => {
        return (
          <RenderWhen
            when={isAuth === undefined ? undefined : isAuth === true ? false : true}
            componentToRender={() => <ForgotPassPage {...props} />}
            redirectComponent={() => <Redirect to={'/dashboard'} />}
          />
        )
      },
    },
    {
      path: '/confirm-new-password',
      exact: true,
      key: keys[3],
      component: (props) => {
        return (
          <RenderWhen
            when={true}
            componentToRender={() => <ConfirmNewPassword {...props} />}
          />
        )
      },
    },
    {
      path: '/dashboard',
      exact: false,
      key: keys[4],
      component: (props) => {
        return (
          <RenderWhen
            when={isAuth}
            componentToRender={() => <Dashboard {...props} />}
            redirectComponent={() => <Redirect to={'/login'} />}
          />
        )
      }
    }
  ];

  useEffect(() => {
    const getHcp = async () => {
      try {
        const { data } = await request.get('/hcps/me');
        if (data.ok) {
          dispatch('login', data.hcp);
        } else {
          dispatch('logout');
        }
      } catch (e) {
        dispatch('logout');
      }
    }
    getHcp();
  }, [])

  try {
    const { match: { path } } = props;
    return (
      <div>
        <RoutesBuilder
          routesProps={ROUTES}
          rootPath={path}
          notFoundComponent={NotFoundSection}
        />
      </div>
    );
  } catch (e) {
    console.log(e);
  }

};

export default Website;