import styled from 'styled-components';

const Body = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 29px - 8px);
`

const Message = styled.h3`
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  margin: 0;
`

const Vaccines = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  flex-direction: column;
  display: flex;
  max-height: 350px;
`

const Row = styled.div`
  padding: 12px 0;
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 14px;
  min-height: 80px;
`

const IconWrapper = styled.div`
  background-color: #8455C7;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  margin-right: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Details = styled.div`
  margin-right: auto;
`

const VaccineName = styled.div`
  font-size: 14px;
  line-height: 20px;
`

const UserName = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #959595;
  text-transform: capitalize;
`

const Date = styled.div`
  font-size: 10px;
  line-height: 18px;
  color: #959595;
  text-transform: capitalize;
  display: ${props => props.type === 'mobile' ? 'block' : 'none'};
  @media(min-width: 1024px) {
    display: ${props => props.type === 'mobile' ? 'none' : 'block'};
  }
`

export { Body, Message, Vaccines, Row, IconWrapper, Details, VaccineName, UserName, Date }