import styled from 'styled-components';

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

const HeaderEffect = styled.div`
  background-color: #A4DBFF;
  width: 100%;
  height: 160px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
`

const Logo = styled.img`
  position: fixed;
  bottom: 32px;
  left: 32px;
`

const Img = styled.img`
  margin-top: 0;
  max-width: 225px;
`


export { Wrapper, HeaderEffect, Img, Logo }