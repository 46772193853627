import React, { Component } from "react";

import Dropzone from "react-dropzone";

import { DropContainer, UploadMessage } from "./styles";

export default class Upload extends Component {
  renderDragMessage = (isDragActive, isDragReject) => {
    if (!isDragActive) {
      return <UploadMessage>{this.props.translations["DOCUMENT_UPLOAD_AREA_DRAG_FILE"]}</UploadMessage>;
    }

    if (isDragReject) {
      return <UploadMessage type="error">{this.props.translations["DOCUMENT_UPLOAD_AREA_NOT_SUPPORTED"]}</UploadMessage>;
    }

    return <UploadMessage type="success">{this.props.translations["DOCUMENT_UPLOAD_AREA_DROP_FILE"]}</UploadMessage>;
  };

  render() {

    const { onUpload } = this.props;

    return (
      <Dropzone accept="application/pdf" onDropAccepted={onUpload}>
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <DropContainer
            {...getRootProps()}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
          >
            <input {...getInputProps()} />
            {this.renderDragMessage(isDragActive, isDragReject)}
          </DropContainer>
        )}
      </Dropzone>
    );
  }
}
