import React, { useState } from 'react';

export const HcpContext = React.createContext();

export const HcpProvider = ({ children }) => {

  const [state, setState] = useState({
    isAuth: undefined,
    data: null
  });

  const reducer = (type, data) => {
    switch (type) {
      case 'login':
        setState({
          isAuth: true,
          data
        });
        break;
      case 'update':
        setState({
          isAuth: true,
          data: data
        });
        break;
      case 'logout':
        setState({
          isAuth: false,
          data: null
        });
        break;
    }
  }

  return (
    <HcpContext.Provider value={{
      state,
      dispatch: reducer
    }}>
      {children}
    </HcpContext.Provider>
  )
};