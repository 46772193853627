import styled from 'styled-components';

const Card = styled.div`
  width: 100%;
`

const Vaccines = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`

const CardTitle = styled.h3`
  margin-bottom: 15px;
`

export { Vaccines, Card, CardTitle };