import React, { useContext } from 'react';
import { SelectWrapper } from './Components';
import { TranslationsContext } from '../../context/Translations';
import Select from '../Select/Select'

const SelectLangs = () => {

  const {
    dispatch,
    langs,
    selectedLang
  } = useContext(TranslationsContext);

  return (
    <SelectWrapper>
      <Select
        id="language-select-id"
        value={selectedLang}
        transparent
        onChange={(value) => dispatch('new-language', value)}
        options={langs && langs.map(lang => ({
          value: lang,
          label: lang
        }))}
      />
    </SelectWrapper>

  )
}

export default SelectLangs;