import React, { useState, useEffect, useContext } from "react";
import hcpApprovedOnly from "../../HOC/HcpApprovedOnly";
import moment from "moment";
import config from "./config.js";
import {
  Page,
  Form,
  InputWrapper,
  Title,
  Button,
  UploaderContainer,
  UploaderWrapper,
  Error,
} from "./Components";
import Input from "../../components/Input/Input";
import FileUploader from "../../components/FileUploader/FileUploader";
import Select from "../../components/Select/Select";
import { request, getInputValuesFromForm } from "../../utils/";
import { HcpContext } from "../../context/Hcp";
import { TranslationsContext } from "../../context/Translations";

import "./NewUser.css";

const NewUser = ({ history }) => {
  const [files, setFiles] = useState({});
  const [countries, setCountries] = useState([]);
  const [countryPlans, setCountryPlans] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const { state: hcpstate } = useContext(HcpContext);
  const { translations, selectedLang } = useContext(TranslationsContext);

  const { inputs } = config(translations);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const { data } = await request.get("/hcps/form-data");
        const countriesOptions = (data || []).map((country) => {
          const {
            _id,
            populated_country: {
              country: { name },
            },
          } = country;
          return {
            value: _id,
            label: name,
            plans: country.plans.map((plan) => {
              return {
                value: plan._id,
                label: `${plan.displayName} (${plan.currency} ${plan.price})`,
                price: plan.price,
              };
            }),
          };
        });
        setCountries(countriesOptions);
      } catch (e) {
        //
      }
    };
    getCountries();
    files > 2;
  }, []);

  const buildFields = (fields) => {
    let allFields = fields.map((input, index) => {
      const { name } = input;

      return (
        <React.Fragment key={`${name}-${index}`}>
          <InputWrapper>
            {name !== "gender" && name !== "nationality" ? (
              <Input
                defaultValue={""}
                {...input}
                inputProps={{ pattern: input.pattern }}
              />
            ) : (
              <Select
                {...input}
                style={{ width: "100%", marginTop: "auto", height: "53.83px" }}
                labelId={`label-${name}-${index}`}
                fallbackMessage={translations["HCP_CREATE_USER_NO_OPTIONS"]}
                errorMessage={null}
                options={
                  name === "gender"
                    ? [
                        {
                          value: "male",
                          label: translations["USER_PROFILEPAGE_GENDER_MALE"],
                        },
                        {
                          value: "female",
                          label: translations["USER_PROFILEPAGE_GENDER_FEMALE"],
                        },
                      ]
                    : countries.map((country) => {
                        return {
                          value: country.label,
                          label: country.label,
                        };
                      })
                }
                defaultValue={""}
                required
                classes={{
                  root: "select-input-new-user",
                }}
              />
            )}
          </InputWrapper>
          {index === fields.length - 1 ? (
            <InputWrapper>
              <Select
                style={{ width: "100%", marginTop: "auto", height: "53.83px" }}
                label={"Country"}
                name={"country"}
                labelId={"new-user-country-label"}
                fallbackMessage={translations["HCP_CREATE_USER_NO_OPTIONS"]}
                errorMessage={null}
                options={countries}
                defaultValue={""}
                onChange={(countryId) => {
                  const selectedCountry = countries.find(
                    (country) => country.value === countryId
                  );
                  setCountryPlans(selectedCountry.plans);
                }}
                required
                classes={{
                  root: "select-input-new-user",
                }}
              />
            </InputWrapper>
          ) : null}
        </React.Fragment>
      );
    });
    return allFields;
  };

  const formInputs = buildFields(inputs);

  const createNewUser = async (e) => {
    try {
      setLoading(true);

      const formData = new FormData();

      const values = getInputValuesFromForm(e, ["text", "email", "select"]);

      if (values.plan === "placeholder") {
        throw { message: translations["PLAN_ERROR_SELECT_VALID"] };
      }

      const { documentFile, vaccinationCard } = files;

      if (documentFile)
        formData.append("file.documentFile", documentFile, documentFile.name);
      if (vaccinationCard)
        formData.append(
          "file.vaccinationCard",
          vaccinationCard,
          vaccinationCard.name
        );

      let userData = {
        ...values,
        username: values["email"],
      };

      if (hcpstate.data.clinic) {
        userData["clinic"] = hcpstate.data.clinic._id;
        userData["planCreatedAt"] = hcpstate.data.clinic._id;
      }

      formData.append("data", JSON.stringify(userData));

      const { data } = await request.post(
        `/hcps/new-user?lang=${selectedLang}`,
        formData
      );

      if (data.ok) {
        history.push({
          pathname: "/dashboard/success-feedback",
          state: {
            message: `${translations["HCP_CREATE_USER_CREATED"]}. </br> <b>${data.user?.name}</b>.`,
          },
        });
      }
    } catch (e) {
      let errorMessage = "";

      const { isAxiosError } = e;

      if (isAxiosError) {
        errorMessage =
          typeof e.response.data.message === "string"
            ? e.response.data.message
            : e.message;
      } else {
        errorMessage = e.message;
      }

      setError(errorMessage);
      setLoading(false);
    }
  };

  return (
    <Page>
      <Title>{translations["HCP_CREATE_USER_TITLE"]}</Title>
      <Form id="new-user-form" onSubmit={createNewUser}>
        {formInputs}
        <Button form={"new-user-form"} loading={isLoading.toString()}>
          {translations["HCP_CREATE_USER_BUTON_SUBMIT"]}
        </Button>
        <Error>{error}</Error>
      </Form>
    </Page>
  );
};

export default hcpApprovedOnly(NewUser);
