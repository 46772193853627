module.exports = (texts) => {
  return {
    inputs: [
      {
        type: "text",
        name: "prefix",
        placeholder: texts["HCP_CREATE_USER_PREFIX"],
        label: texts["HCP_CREATE_USER_PREFIX"],
      },
      {
        type: "text",
        name: "name",
        placeholder: texts["HCP_CREATE_USER_FIRST_NAME"],
        label: texts["HCP_CREATE_USER_FIRST_NAME"],
        required: true,
      },
      {
        type: "text",
        name: "surname",
        placeholder: texts["HCP_CREATE_USER_SURNAME"],
        label: texts["HCP_CREATE_USER_SURNAME"],
        required: true,
      },
      {
        type: "text",
        name: "birthday",
        placeholder: texts["HCP_CREATE_USER_BIRTHDAY_PLACEHOLDER"],
        label: texts["HCP_CREATE_USER_BIRTHDAY"],
        required: true,
      },
      {
        type: "select",
        name: "gender",
        placeholder: texts["HCP_CREATE_USER_GENDER"],
        label: texts["HCP_CREATE_USER_GENDER"],
        required: true,
      },
      {
        type: "email",
        name: "email",
        placeholder: texts["HCP_CREATE_USER_EMAIL"],
        label: texts["HCP_CREATE_USER_EMAIL"],
        required: true,
      },
      {
        type: "text",
        name: "telephone",
        placeholder: texts["HCP_CREATE_USER_TELEPHONE"],
        label: texts["HCP_CREATE_USER_TELEPHONE"],
      },
      {
        type: "text",
        name: "mobile",
        placeholder: texts["HCP_CREATE_USER_MOBILE"],
        label: texts["HCP_CREATE_USER_MOBILE"],
        required: true,
      },
      {
        type: "text",
        name: "nationality",
        placeholder: texts["HCP_CREATE_USER_NATIONALILTY"],
        label: texts["HCP_CREATE_USER_NATIONALILTY"],
        required: true,
      },
      {
        type: "text",
        name: "documentNumber",
        placeholder: texts["HCP_CREATE_USER_DOCUMENT_NUMBER"],
        label: texts["HCP_CREATE_USER_DOCUMENT_NUMBER"],
        required: true,
      },
      {
        type: "text",
        name: "documentType",
        placeholder: texts["HCP_CREATE_USER_DOCUMENT_TYPE"],
        label: texts["HCP_CREATE_USER_DOCUMENT_TYPE"],
        required: true,
      },
      {
        type: "text",
        name: "documentIssuedBy",
        placeholder: texts["HCP_CREATE_USER_DOCUMENT_ISSUED_BY"],
        label: texts["HCP_CREATE_USER_DOCUMENT_ISSUED_BY"],
        required: true,
      },
      {
        type: "text",
        name: "addressLineOne",
        placeholder: texts["HCP_CREATE_USER_ADDRESS_LINE_ONE"],
        label: texts["HCP_CREATE_USER_ADDRESS_LINE_ONE"],
        required: true,
      },
      {
        type: "text",
        name: "addressLineTwo",
        placeholder: texts["HCP_CREATE_USER_ADDRESS_LINE_TWO"],
        label: texts["HCP_CREATE_USER_ADDRESS_LINE_TWO"],
      },
      {
        type: "text",
        name: "borough",
        placeholder: texts["HCP_CREATE_USER_ADDRESS_BOROUGH"],
        label: texts["HCP_CREATE_USER_ADDRESS_BOROUGH"],
        required: true,
      },
      {
        type: "text",
        name: "town",
        placeholder: texts["HCP_CREATE_USER_ADDRESS_TOWN"],
        label: texts["HCP_CREATE_USER_ADDRESS_TOWN"],
        required: true,
      },
      {
        type: "text",
        name: "state",
        placeholder: texts["HCP_CREATE_USER_ADDRESS_STATE"],
        label: texts["HCP_CREATE_USER_ADDRESS_STATE"],
        required: true,
      },
      {
        type: "text",
        name: "postalCode",
        placeholder: texts["HCP_CREATE_USER_ADDRESS_POSTAL_CODE"],
        label: texts["HCP_CREATE_USER_ADDRESS_POSTAL_CODE"],
        required: true,
      },
    ],
  };
};
