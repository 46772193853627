import styled from 'styled-components';

const Frame = styled.div`
  width: 100%;
  height: 100%;
  max-width: 140px;
  max-height: 140px;
  border-radius: 50%;
  border: 2px solid #3298DC;
  padding: 4px;
  background: #FFF;
  margin: 0 auto;
`

const Img = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-image: url('${props => props.src}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

const Button = styled.button`
  padding: 6px 20px;
  border: 1px solid rgba(24,32,46);
  color: rgba(24,32,46);
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin: 10px auto;
  display: block;
  background: #FFF;
  min-width: 140px;

  &:hover{
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  }

  background-image: radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.3) 10%, transparent 10%);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 0 0;

  animation: ripple 1s;
  animation-iteration-count: ${props => props.loading ? 'infinite' : 0};

  @keyframes ripple {
    from {
      background-size: 0 0;
    }
    to {
      background-size: 1000% 1000%;
    }
  }
`

export { Frame, Button, Img } 