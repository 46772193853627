import React from 'react';
import ForgotPassForm from '../../components/ForgotPassForm/ForgotPassForm';
import AuthPageWrapper from '../../components/AuthPageWrapper/AuthPageWrapper';

const ForgotPassPage = () => {
  try {
    return (
      <AuthPageWrapper
        bgImgSrc={'/images/forgot_pass.png'}
        form={ForgotPassForm}
      />
    );
  } catch (e) {
    return null;
  }
};


export default ForgotPassPage;