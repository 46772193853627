import React from 'react';
import { buildUrl } from '../../utils/';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

const RoutesBuilder = ({ routesProps, rootPath, notFoundComponent: NotFound }) => {
  try {
    return (
      <Switch>
        {
          (routesProps || []).map(routeProps => {
            const { component: Component, path, exact, ...props } = routeProps;

            const url = buildUrl([rootPath, path]);

            try {
              return (
                <Route
                  {...props}
                  exact={exact}
                  path={url}
                  render={(routerProps) => {
                    return <Component {...routerProps} />;
                  }}
                />
              );
            } catch (e) {
              console.log(e);
              return null;
            }
          })
        }
        <Route
          render={(props) => {
            if (NotFound) {
              return <NotFound {...props} />
            } else {
              return <h1> 404: Not Found! </h1>
            }
          }}
        />
      </Switch>
    )

  } catch (e) {
    console.log(e);
    return null;
  }
}

RoutesBuilder.propTypes = {
  routesPath: PropTypes.shape({
    path: PropTypes.string.isRequired,
    exact: PropTypes.bool,
    component: PropTypes.element
  }),
  rootPath: PropTypes.string.isRequired,
  notFoundComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.instanceOf(undefined)
  ])
}

export default RoutesBuilder;