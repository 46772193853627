import React, { useRef, useContext } from 'react';
import Icon from '../Icon/Icon';
import { Frame, Button, Img } from './Components';
import { TranslationsContext } from '../../context/Translations';

const Avatar = ({ src, onChange, loading }) => {

  const input = useRef(null);

  const { translations } = useContext(TranslationsContext)

  const onClick = () => {
    try {
      const { current } = input;
      current.click();
    } catch (_) {
      //
    }
  }

  const handleChange = () => {
    const target = document.getElementById('avatar-image-uplader');
    const file = target.files[0];
    if (onChange) onChange(file)
  }

  return (
    <>
      <Frame>
        {!src ?
          <Icon icon="doctor" width={'100%'} height={'100%'} style={{ borderRadius: '50%' }} />
          : <Img src={src} />
        }
      </Frame>
      <Button onClick={onClick} loading={loading ? loading : undefined}> {loading ? 'Loading...' : translations["HCP_AVATAR_CHANGE_IMAGE"]} </Button>
      <input type={'file'} style={{ display: 'none' }} ref={input} onChange={handleChange} accept={"image/*"} id={'avatar-image-uplader'} />
    </>
  )

}

export default Avatar;