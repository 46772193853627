import React, { useContext, useState, useEffect } from "react";
import hcpApprovedOnly from "../../HOC/HcpApprovedOnly";
import { HcpContext } from "../../context/Hcp";
import { TranslationsContext } from "../../context/Translations";
import {
  Wrapper,
  Container,
  Details,
  Title,
  IconLink,
  CardWrapper,
  CardLink,
  LoaderWrapper,
} from "./Components";
import HcpDetails from "../../components/HcpDetails/HcpDetails";
import Icon from "../../components/Icon/Icon";
import { request } from "../../utils/";
import { CircularProgress } from "@material-ui/core";
import VaccineHistory from "../../components/VaccineHistory/VaccineHistory";

const Homepage = () => {
  const { state: hcpState } = useContext(HcpContext);

  const { translations } = useContext(TranslationsContext);

  const [loading, setLoading] = useState(true);
  const [vaccines, setVaccines] = useState([]);

  useEffect(() => {
    const getVaccines = async () => {
      try {
        setLoading(true);
        const { data: vaccines } = await request.get(
          `/hcps/vaccines-given?hcpId=${hcpState.data._id}`
        );
        setVaccines(vaccines);
      } catch (e) {
        //
      } finally {
        setLoading(false);
      }
    };
    getVaccines();
  }, []);

  const { certificate, name, surname } = hcpState.data;

  const { prefix } = certificate;

  return (
    <Wrapper>
      <Container type={"details"}>
        <Details>
          <Title>
            {`${translations["HCP_HOMEPAGE_HELLO"] || ""} ${prefix || ""} ${
              name || ""
            } ${surname || ""}`}
          </Title>
          <HcpDetails hcp={hcpState.data} />
          <IconLink to={"/dashboard/profile"}>
            <Icon icon={"edit"} height={"16px"} width={"16px"} />
          </IconLink>
        </Details>
        <CardWrapper>
          <CardLink color={"#3298DC"} to={"/dashboard/vaccines"}>
            <Icon
              icon={"syringe"}
              height={"24px"}
              width={"24px"}
              style={{ marginBottom: "12px" }}
            />
            <span>{translations["HCP_HOMEPAGE_APPLY_VACCINES"]}</span>
          </CardLink>
          <CardLink color={"#00AFAA"} to={"/dashboard/users"}>
            <Icon
              icon={"person-add"}
              height={"24px"}
              width={"24px"}
              style={{ marginBottom: "12px" }}
            />
            <span>{translations["HCP_HOMEPAGE_ADD_USER"]}</span>
          </CardLink>
          <CardLink color={"#cc3f4d"} to={"/dashboard/transcribe-records"}>
            <Icon
              icon={"file"}
              height={"24px"}
              width={"24px"}
              style={{ marginBottom: "12px" }}
            />
            <span>{translations["HCP_HOMEPAGE_TRANSCRIBE_RECORDS"]}</span>
          </CardLink>
          <CardLink color={"#935f9c"} to={"/dashboard/digital-certificates"}>
            <Icon
              icon={"badge"}
              height={"24px"}
              width={"24px"}
              style={{ marginBottom: "12px" }}
            />
            <span>{translations["HCP_HOMEPAGE_DIGITAL_CERTIFICATES"]}</span>
          </CardLink>
        </CardWrapper>
      </Container>
      <Container type={"vaccines"}>
        <Title>{translations["HCP_HOMEPAGE_LAST_VACCINES"]}</Title>
        {loading ? (
          <LoaderWrapper>
            <CircularProgress />
          </LoaderWrapper>
        ) : (
          <VaccineHistory vaccines={vaccines} />
        )}
      </Container>
    </Wrapper>
  );
};

export default hcpApprovedOnly(Homepage);
