import React, { useState, useContext } from "react";
import validator from "validator";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Typography,
  FormControl,
  TextField,
} from "@material-ui/core/";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import { ErrorMessage, StyledButton } from "./Components";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { request } from "../../utils";
import { requestBlockchain } from "../../../utils/request";
import { HcpContext } from "../../context/Hcp";
import { TranslationsContext } from "../../context/Translations";
import { configEnv } from  '../../utils'

import "./CardItem.css";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "0.75rem",
    fontWeight: "400",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  textField: {
    fontSize: "0.75rem",
    width: "100%",
  },
  button: {
    height: "85%",
  },
}));

const CardItemTranscription = ({
  vaccineName,
  vaccineId,
  applied,
  producers,
  userId,
  vaccineType,
  refreshUser,
  hcpName,
  hcpNumber,
  vaccineLotNumber,
  date,
  producerName,
  wasTranscribed,
  transcription,
  vaccBrandName,
}) => {
  const classes = useStyles();
  const [producer, setProducer] = useState("");
  const [lotNumber, setLotNumber] = useState("");
  const [vaccApplyDate, setVaccApplyDate] = useState("");
  const [clinic, setClinic] = useState("");
  const [hcpApplyName, setHcpApplyName] = useState("");
  const [hcpRegistryNumber, setHcpRegistryNumber] = useState("");
  const [vaccNotes, setVaccNotes] = useState("");
  const [brandName, setBrandName] = useState("");
  const [error, setError] = useState({ producer: false, lotNumber: false });
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState("");

  const {
    state: { data: hcp },
  } = useContext(HcpContext);
  const { translations, selectedLang } = useContext(TranslationsContext);

  const registerBlockchain = async (userId) => {
    const { data: result } = await requestBlockchain.get(
      `/client-app/certificates?id=${userId}`,
      {
        id: userId,
      }
    );

    result.user = {
      ...result.user,
      id: userId,
    };

    const userData = result.user;
    const userVaccines = result.vaccines;

    const userDocumentType = userData.documentType;
    const userDocumentNumber = userData.documentNumber;
    const userFullName = userData.fullName;
    const userBirthday = userData.birthday;
    const userGender =
      userData.gender &&
      (userData.gender === "male"
        ? translations["USER_PROFILEPAGE_GENDER_MALE"]
        : translations["USER_PROFILEPAGE_GENDER_FEMALE"]);
    const userNationality = userData.nationality;

    const isThereEnoughInfo = !!(
      userFullName &&
      userDocumentType &&
      userDocumentNumber &&
      userGender &&
      userBirthday &&
      userNationality
    );

    if (isThereEnoughInfo) {
      return await requestBlockchain.post(
        `${configEnv.REACT_APP_HASH_API}/hash-register`,
        // "https://hash.immunie.digital/hash-register",
        // "http://localhost:8000/hash-register",
        {
          data: {
            userData,
            userVaccines,
          },
        }
      );
    } else {
      return;
    }
  };

  const onApply = async () => {
    try {
      setError({});
      setErrorMessage("");
      setLoading(true);

      if (!validator.isDate(vaccApplyDate)) {
        setError({ date: true });
      }

      // Saving
      await request.post(
        `/hcps/transcribe-vaccine?vaccine=${vaccineId}&hcp=${
          hcp._id
        }&vaccineType=${vaccineType}&user=${userId}&date=${moment(
          vaccApplyDate
        ).format()}&clinic=${clinic}&hcpName=${hcpApplyName}&registerNumber=${hcpRegistryNumber}&producer=${producer}&brandName=${brandName}&lotNumber=${lotNumber}&notes=${vaccNotes}&transcribed=${true}&lang=${selectedLang}`
      );

      await refreshUser();
      // await registerBlockchain(userId);
    } catch (e) {
      const isAxiosError = e.isAxiosError;
      if (isAxiosError) {
        if (
          e.response.data.message === "Blockchain Duplicated Hash" ||
          e.response.data.message === "Unauthorized Operation at Blockchain."
        ) {
          await refreshUser();
        } else {
          setErrorMessage(e.response.data.message);
        }
      } else {
        setErrorMessage(
          translations["HCP_ADD_VACCINE_CARD_SOMETHING_WENT_WRONG"]
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Accordion id={"card-item-accordion"}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>
          <span>{vaccineName + " "}</span>
        </Typography>
        {!applied && (
          <Chip
            style={{ marginLeft: "5px", marginTop: "-3px" }}
            size="small"
            label={translations["HCP_ADD_VACCINE_CARD_NOT_APPLIED"]}
            className={classes.heading}
          />
        )}
        {wasTranscribed && (
          <Chip
            style={{ marginLeft: "5px", marginTop: "-3px" }}
            size="small"
            color="primary"
            label={translations["HCP_ADD_VACCINE_CARD_TRASNCRIBED"]}
            className={classes.heading}
          />
        )}
      </AccordionSummary>
      <AccordionDetails>
        {!applied ? (
          <FormControl variant="outlined" className={classes.formControl}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <TextField
                  helperText={error["date"] ? "YYYY/MM/DD" : ""}
                  error={error["date"]}
                  value={vaccApplyDate}
                  label={translations["HCP_TEXT_DATE_LABEL"]}
                  variant="outlined"
                  className={classes.textField}
                  onChange={({ target: { value } }) => {
                    setError({ date: false });
                    setVaccApplyDate(value);
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  error={error["vaccNotes"]}
                  value={vaccNotes}
                  label={translations["HCP_TEXT_VACC_NOTES_LABEL"]}
                  variant="outlined"
                  className={classes.textField}
                  onChange={({ target: { value } }) => {
                    setError({ vaccNotes: false });
                    setVaccNotes(value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  error={error["clinic"]}
                  value={clinic}
                  label={translations["HCP_TEXT_CLINIC_LABEL"]}
                  variant="outlined"
                  className={classes.textField}
                  onChange={({ target: { value } }) => {
                    setError({ clinic: false });
                    setClinic(value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  error={error["hcpApplyName"]}
                  value={hcpApplyName}
                  label={translations["HCP_TEXT_HCP_APPLY_NAME_LABEL"]}
                  variant="outlined"
                  className={classes.textField}
                  onChange={({ target: { value } }) => {
                    setError({ hcpApplyName: false });
                    setHcpApplyName(value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  error={error["hcpRegistryNumber"]}
                  value={hcpRegistryNumber}
                  label={translations["HCP_TEXT_HCP_REGISTER_NUMBER_LABEL"]}
                  variant="outlined"
                  className={classes.textField}
                  onChange={({ target: { value } }) => {
                    setError({ hcpRegistryNumber: false });
                    setHcpRegistryNumber(value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  error={error["producer"]}
                  value={producer}
                  label={translations["HCP_ADD_VACCINE_CARD_PRODUCER"]}
                  variant="outlined"
                  className={classes.textField}
                  onChange={({ target: { value } }) => {
                    setError({ producer: false });
                    setProducer(value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  error={error["brandName"]}
                  value={brandName}
                  label={translations["HCP_TEXT_BRAND_NAME_LABEL"]}
                  variant="outlined"
                  className={classes.textField}
                  onChange={({ target: { value } }) => {
                    setError({ brandName: false });
                    setBrandName(value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  error={error["lotNumber"]}
                  value={lotNumber}
                  label={translations["HCP_ADD_VACCINE_CARD_LOT_NUMBER"]}
                  variant="outlined"
                  className={classes.textField}
                  onChange={({ target: { value } }) => {
                    setError({ lotNumber: false });
                    setLotNumber(value);
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <StyledButton
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: "auto" }}
                  size="large"
                  onClick={onApply}
                  loading={!!isLoading.toString()}
                >
                  {translations["HCP_TEXT_BTN_TRANSCRIBE"]}
                </StyledButton>
              </Grid>
            </Grid>
          </FormControl>
        ) : (
          <div>
            {wasTranscribed ? (
              <>
                <Typography className={classes.heading}>
                  {translations["CLIENT_VACCINE_TRANSCRIPTION_DATE"]}:{" "}
                  <b>{date}</b>.
                </Typography>
                <Typography className={classes.heading}>
                  {translations["HCP_ADD_VACCINE_CARD_DATE"]}:{" "}
                  <b>
                    {transcription.date ? transcription.date : "Not Avaliable"}
                  </b>
                  .
                </Typography>
                <Typography className={classes.heading}>
                  {translations["HCP_TEXT_TRANSCRIBED_BY"]}: <b>{hcpName}</b>
                </Typography>
              </>
            ) : (
              <Typography className={classes.heading}>
                {translations["HCP_ADD_VACCINE_CARD_DATE"]}: <b>{date}</b>.
              </Typography>
            )}
            <Typography className={classes.heading}>
              {translations["HCP_ADD_VACCINE_APPLIED_BY"]}{" "}
              <b>
                {wasTranscribed
                  ? transcription.hcpName
                    ? transcription.hcpName
                    : translations["HCP_TEXT_NOT_AVAILABLE_INFO"]
                  : hcpName}
              </b>{" "}
              {translations["HCP_ADD_VACCINE_HCP_REGISTRY"]}{" "}
              <b>
                {wasTranscribed
                  ? transcription.registerNumber
                    ? transcription.registerNumber
                    : translations["HCP_TEXT_NOT_AVAILABLE_INFO"]
                  : hcpNumber}
              </b>
              .
            </Typography>
            <Typography className={classes.heading}>
              {translations["HCP_ADD_VACCINE_CARD_LOT_NUMBER"]}:{" "}
              <b>
                {wasTranscribed
                  ? transcription.lotNumber
                    ? transcription.lotNumber
                    : translations["HCP_TEXT_NOT_AVAILABLE_INFO"]
                  : vaccineLotNumber}
              </b>
              , {translations["HCP_ADD_VACCINE_PRODUCED_BY"]}{" "}
              <b>
                {wasTranscribed
                  ? transcription.producer
                    ? transcription.producer
                    : translations["HCP_TEXT_NOT_AVAILABLE_INFO"]
                  : producerName}
              </b>
              .
            </Typography>
            <Typography className={classes.heading}>
              {translations["HCP_TEXT_BRAND_NAME_LABEL"]}:{" "}
              <b>
                {vaccBrandName
                  ? vaccBrandName
                  : translations["HCP_TEXT_NOT_AVAILABLE_INFO"]}
              </b>
            </Typography>
            {wasTranscribed ? (
              <>
                <Typography className={classes.heading}>
                  {translations["HCP_TEXT_CLINIC_LABEL"]}:{" "}
                  <b>
                    {transcription.clinic
                      ? transcription.clinic
                      : translations["HCP_TEXT_NOT_AVAILABLE_INFO"]}
                  </b>{" "}
                </Typography>
                <Typography className={classes.heading}>
                  {translations["HCP_TEXT_VACC_NOTES_LABEL"]}:{" "}
                  <b>
                    {transcription.notes
                      ? transcription.notes
                      : translations["HCP_TEXT_NOT_AVAILABLE_INFO"]}
                  </b>{" "}
                </Typography>
              </>
            ) : (
              ""
            )}
          </div>
        )}
      </AccordionDetails>

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Accordion>
  );
};

export default CardItemTranscription;
