import styled from 'styled-components';
import { Label } from '@buffetjs/core';
import { Link } from 'react-router-dom';

const Form = styled.form`
  min-height: 16rem;
  width: 100%;
  max-width: 34rem;
  margin: 1.4rem auto 0px;
  padding: 1.5rem;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
`

const StyledTitle = styled(Label)`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 12px;
`

const Button = styled.button`
  height: 40px;
  width: 100%;
  max-width: 28rem;
  margin: 10px auto;
  color: #FBFBFB;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  background: #3298DC;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  border: none;
  cursor: pointer;

  background-image: radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.3) 10%, transparent 10%);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 0 0;

  animation: ripple 1s;
  animation-iteration-count: ${props => props.loading === 'true' ? 'infinite' : 0};

  @keyframes ripple {
    from {
      background-size: 0 0;
    }
    to {
      background-size: 1000% 1000%;
    }
  }
`

const Message = styled.p`
  color: red;
  font-size: 12px;
  width: 100%;
  text-align: center;
  margin-top: 0px;
  margin-bottom: ${props => props.general ? '24px' : 0};
`

const StyledLink = styled(Link)`
  background: transparent;
  border: 1px solid #707070;
  height: 40px;
  width: 100%;
  margin: 10px auto;
  color: #000000;
  text-transform: none;
  text-align: center;
  text-decoration: none;
  line-height: 40px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  max-width: 28rem;
`

const UploaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 100%;
  align-items: center;
  margin-bottom: 24px;
`

export {
  Form,
  StyledTitle,
  Button,
  Message,
  StyledLink,
  UploaderContainer
}