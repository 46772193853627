import styled from 'styled-components';

const Title = styled.h1`
  font-size: 20px;
  line-height: 24px;
  max-width: 800px;
  margin: 0 auto;
  padding-left: 20px;
`

const Page = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto 20px auto;
`

const InputWrapper = styled.div`
  margin-right: 8px;
  width: 100%;
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const Button = styled.button`
  background-color: #3298DC;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 19px;
  color: #FFFFFF;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  margin-left: auto;
  width: 100%;
  cursor: pointer;

  background-image: radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.3) 10%, transparent 10%);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 0 0;

  animation: ripple 1s;
  animation-iteration-count: ${props => props.loading ? 'infinite' : 0};

  @keyframes ripple {
    from {
      background-size: 0 0;
    }
    to {
      background-size: 1000% 1000%;
    }
  }
`

const ErrorMessage = styled.pre`
  font-size: 14px;
  text-align: center;
  color: red;
`

export { Page, Form, InputWrapper, Title, ButtonWrapper, Button, ErrorMessage }