import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import Feedback from '../../components/Feedback/Feedback';
import { Page, Button } from './Components';
import { TranslationsContext } from '../../context/Translations';

const FeedbackPage = ({ location, history }) => {

  const { state } = location;
  
  const { translations } = useContext(TranslationsContext);

  if (!state) {
    return <Redirect to={'/dashboard'} />
  }

  const { message } = state;

  return (
    <Page>
      <Feedback message={message}
        button={() => (
          <Button onClick={history.goBack}>
            {translations['HCP_FEEDBACK_GO_BACK']}
          </Button>
        )}
      />
    </Page >
  )

}

export default FeedbackPage;