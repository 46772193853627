import styled from 'styled-components';
import { Select, MenuItem, InputLabel, NativeSelect } from '@material-ui/core';

const StyledSelect = styled(Select)`
  background: ${props => props.transparent === 'true' ? 'transparent' : '#FFFFFF'};
  border: none;
  border-bottom: ${props => props.error ? 'none' : '1px solid #EDEDED'} !important;
  margin-bottom: ${props => props.error ? '16px' : '32px'} !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 13.3333px !important;
  padding-bottom: 8px !important;
`

const Message = styled.p`
  color: red;
  font-size: 12px;
  width: 100%;
  text-align: center;
  margin-top: 0px;
  margin-bottom: ${props => props.general ? '24px' : 0};
`

const Option = styled(MenuItem)`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 13.3333px !important;
`

const SelectLabel = styled(InputLabel)`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 13.3333px !important;
  z-index: 2;
`

export {
  Message,
  SelectLabel,
  StyledSelect,
  Option
}