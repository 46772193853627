import { useState } from 'react';
import { validateEmail } from '../utils';

export const useLoginForm = (texts) => {
  const [values, setValues] = useState({
    email: '',
    password: '',
  });
  const [touched, setTouched] = useState({
    email: false,
    password: false,
  });
  const [errors, setErrors] = useState({
    email: '',
    password: ''
  });

  const insertField = (type, value) => {
    switch (type) {
      case 'email':
        setTouched({ ...touched, email: true });
        if (validateEmail(value)) {
          setValues({ ...values, email: value });
          setErrors({ ...errors, email: '' });
        } else {
          setErrors({ ...errors, email: texts["HCP_AUTH_FORM_INVALID_EMAIL"] });
        }
        break;
      case 'password':
        setTouched({ ...touched, password: true });
        if (value) {
          setValues({ ...values, password: value });
          setErrors({ ...errors, password: '' });
        } else {
          setErrors({ ...errors, password: texts["HCP_AUTH_FORM_REQUIRED"] });
        }
        break;
      default:
      // silent
    }
  };

  return {
    insertField,
    values,
    errors,
    touched,
  };
};


export const useRegisterForm = (texts) => {

  const [values, setValues] = useState({
    name: '',
    surname: '',
    email: '',
    password: '',
    confirmPassword: '',
    registryNumber: '',
    telephone: '',
    mobile: '',
    country: '',
    certificate: '',
    clinic: ''
  });

  const [touched, setTouched] = useState({
    name: false,
    surname: false,
    email: false,
    password: false,
    confirmPassword: false,
    registryNumber: false,
    telephone: false,
    mobile: false,
    country: false,
    certificate: false,
    clinic: false
  });

  const [errors, setErrors] = useState({
    name: '',
    surname: '',
    email: '',
    password: '',
    confirmPassword: '',
    registryNumber: '',
    telephone: '',
    mobile: '',
    country: '',
    certificate: '',
    clinic: ''
  });

  const insertField = async (type, value) => {

    switch (type) {
      case 'email':
        if (validateEmail(value)) {
          setErrors({ ...errors, [type]: '' });
        } else {
          setErrors({ ...errors, [type]: texts["HCP_AUTH_FORM_INVALID_EMAIL"] });
        }
        break;
      case 'name':
      case 'surname':
      case 'telephone':
      case 'mobile':
      case 'registryNumber':
      case 'country':
      case 'certificate':
      case 'clinic':
        if (value) {
          setErrors({ ...errors, [type]: '' });
        } else {
          setErrors({ ...errors, [type]: texts["HCP_AUTH_FORM_REQUIRED"] });
        }
        break;
      case 'certificate':
      case 'clinic':
      case 'confirmPassword':
      case 'password':
        if (!value) {
          setErrors({ ...errors, [type]: texts["HCP_AUTH_FORM_REQUIRED"] });
        } else if (
          type === 'password' && value !== values.confirmPassword ||
          type === 'confirmPassword' && value !== values.password
        ) {
          setErrors({ ...errors, confirmPassword: texts["HCP_AUTH_FORM_PASS_MUST_MATCH"], password: '' });
        } else {
          setErrors({ ...errors, password: '', confirmPassword: '' });
        }
        break;
      default:
      // silent
    }

    if (!touched[type]) setTouched({ ...touched, [type]: true });
    setValues({ ...values, [type]: value });
  };


  return {
    insertField,
    values,
    errors,
    touched,
  };
};