import React from "react";
import { FormControl } from "@material-ui/core";
import { Message, StyledSelect, Option, SelectLabel } from "./Components";

const Select = ({
  label,
  options,
  fallbackMessage,
  labelId,
  errorMessage,
  onChange,
  required,
  transparent,
  ...rest
}) => {
  return (
    <FormControl style={{ width: "100%" }} required={required}>
      <SelectLabel id={labelId}>{label}</SelectLabel>
      <StyledSelect
        {...rest}
        transparent={transparent ? "true" : "false"}
        labelId={labelId}
        inputProps={{
          type: rest.type,
        }}
        onChange={({ target: { value } }) => {
          if (value === "placeholder") return;
          if (onChange) onChange(value);
        }}
      >
        {(options || []).length === 0 ? (
          <Option key={"placeholder" + labelId} value={"placeholder"}>
            {fallbackMessage || ""}
          </Option>
        ) : (
          (options || []).map((option) => {
            const { value, label } = option;
            return (
              <Option key={value + labelId} value={value}>
                {label}
              </Option>
            );
          })
        )}
      </StyledSelect>
      <Message>{errorMessage}</Message>
    </FormControl>
  );
};

export default Select;
