import React from 'react';
import {
  DateTimePicker,
} from '@material-ui/pickers';

const DatePicker = ({translations, date, handleDateChange}) => {


  return (
        <DateTimePicker
          margin="normal"
          id="date-time-picker"
          inputVariant="outlined"
          label={translations["HCP_AREA_EXAM_COLLECT_DATE"]}
          value={date}
          fullWidth
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
  );
};

export default DatePicker;
