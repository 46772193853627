import styled from 'styled-components';

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
`

const InputWrapper = styled.div`
  margin-right: 8px;
  width: 100%;
  
  @media(min-width: 769px) {
    max-width: 48%;
  }
`

const Summary = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`

const SummaryDetails = styled.p`
  font-size: 14px;
  font-weight: 400;
  text-transform: ${props => props.email ? 'none' : 'capitalize'};
`

const Title = styled.h1`
  font-size: 20px;
  line-height: 24px;
  max-width: ${props => props.summary === 'true' ? '600px' : 'none'};
  margin: ${props => props.summary === 'true' ? '0 auto' : '0 0 24px 0'};
`

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`
const SubmitButton = styled.button`
  background-color: #3298DC;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 19px;
  color: #FFFFFF;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  margin-left: auto;
  width: 100%;
  cursor: pointer;

  background-image: radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.3) 10%, transparent 10%);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 0 0;

  animation: ripple 1s;
  animation-iteration-count: ${props => props.loading === 'true' ? 'infinite' : 0};

  @keyframes ripple {
    from {
      background-size: 0 0;
    }
    to {
      background-size: 1000% 1000%;
    }
  }
`



export { Form, InputWrapper, Summary, SummaryDetails, Title, IconButton, SubmitButton }