import React, { useState, useEffect } from 'react';
import AuthPageWrapper from '../../components/AuthPageWrapper/AuthPageWrapper';
import qs from 'query-string';
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import styled from "styled-components";


const Center = styled.div`
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ConfirmNewPassword = ({ location, history }) => {

  const queryString = qs.parse(location.search);
  const [submitted, setSubmitted] = useState(false)
  const [failed, setFailed] = useState(false)

  const {
    email,
    password,
    token
  } = queryString;


  useEffect(() => {
    const setPassword = async (email, password, token) => {
      try {
        await axios.post('/hcps/confirm-new-password', {
          email: email,
          password: password,
          token: token,
        });
        window.location.replace("/")
      } catch (e) {
        setFailed(true)
      } finally {
        setSubmitted(true)
      }
    }
    if (!email || !password || !token) {
      window.location.replace("/")
      return null;
    } else {
      setPassword(email, password, token)
    }
  }, [email, history, password, token])

  try {
    return (
      <AuthPageWrapper
        bgImgSrc={'/images/forgot_pass.png'}
        form={() => {
          return (
            <Center>
              {submitted ? null : <CircularProgress />}
              <p style={{ textAlign: 'center' }}>
                {failed ? 'We could not change your password. Please, contact us at support@immunie.org' : null}
              </p>
            </Center>
          )
        }}
      />
    );
  } catch (e) {
    console.log('e', e);
    return null;
  }
};


export default ConfirmNewPassword;