import styled from 'styled-components';
import { Button } from '@material-ui/core/'

const ErrorMessage = styled.p`
  color: red;
  margin: 0;
  padding: 0 16px 16px 16px;
  font-size: 10px;
  text-align: center;
  width: 100%;
`

const StyledButton = styled(Button)`
  background-image: radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.3) 10%, transparent 10%);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 0 0;

  animation: ripple 1s;
  animation-iteration-count: ${props => props.loading === 'true' ? 'infinite' : 0};

  @keyframes ripple {
    from {
      background-size: 0 0;
    }
    to {
      background-size: 1000% 1000%;
    }
  }
`

export { ErrorMessage, StyledButton }