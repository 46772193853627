import styled from 'styled-components';

const Avatar = styled.div`
  background-image: url('${props => props.backgroundImage}');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border: 2px solid #3298DC;
  margin: 12px auto;
  float: none;

  @media(min-width: 1024px) {
    float: left;
    width: 160px;
    height: 160px;
    margin: 12px 24px;
  }
`

const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  width: 100%;
  text-align: center;
  margin: 4px;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-direction: column; 
  @media(min-width: 1024px) {
    text-align: left;
    display: block;
    padding-left: 190px;
    padding-right: 24px;
    line-height: 24px;
    font-size: 14px;
  }
`

export { Avatar, Text }